import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, getClaimStores} from "../../store/slices/userSlice";
import Modal from "react-modal";
import moment from "moment"
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");

const ClaimListing = () => {
    const profile=useSelector(getProfile)
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const [claimListings, setclaimListings] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [load, setload] = useState(false);
  
  
  
    function viewModal(item, type) {
      setIsOpen(true);
      if (type == "userDetail") {
        setUserDetail(item);
        setId(item?._id);
      } else if (type == "delete") {
        setId(item);
      }
      setModalType(type);
      setIsOpen(true);
    }
  
  
    function closeModal() {
      setIsOpen(false);
      setId("")
    }
  
    const userDelete = async (id) => {
      try {
        await dispatch(({id: id})).unwrap();
        setIsOpen(false);
        try {
        ClaimListing();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    };
    
    const ClaimListing = async () => {
      try {
        setload(true);
        setclaimListings(null);
        const response = await dispatch(getClaimStores()).unwrap();
        console.log('responsessss', response?.data)
        setclaimListings(response?.data);
        setload(false);
       
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
        setload(false);
      }
    };
  
    useEffect(() => {
      let mount = true;
      if (mount) {
        ClaimListing();
      }
      return () => {
        mount = false;
      };
    }, []);
  return (
    <>
{profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show wf-modal wf-modal-30`}
>
        <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
        {modalType == "userDetail" ? (
    <>
    <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Claim Listing</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p className="font-size-20 pink-color dm-font mb-0">Business Name</p>
                <p className="font-size-16 black-color">{userDetail?.businessName}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Name</p>
                <p className="font-size-16 black-color">{userDetail?.name}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Email</p>
                <p className="font-size-16 black-color">{userDetail?.email}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Contact Number</p>
                <p className="font-size-16 black-color">{userDetail?.contactNumber}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Relationship</p>
                <p className="font-size-16 black-color">{userDetail?.relationship}</p>
                <p className="font-size-20 pink-color dm-font mb-0">Business Website</p>
                <p className="font-size-16 black-color">{userDetail?.website}</p>
                </div>
            </div>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Claim Listing</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Claim Listing?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => userDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Claim Listing</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Claim Listing</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">        
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
      <p className="font-size-20 pink-color">Claim Listing : {claimListings?.length}</p>
      <div class="table-responsive">
        <table class="table wf-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Business Name</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact Number</th>
              <th scope="col">Relationship</th>
              <th scope="col">Business Website</th>
              <th scope="col">Date</th>
              {/* <th scope="col">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {load ? new Array(6).fill(null).map((_, index) => (<tr><td colSpan={9}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : claimListings?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.store_id?.store_name}</td>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>{item?.phone}</td>
                <td>{item?.relationship}</td>
                <td>{item?.website}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                {/* <td>
                  <div className="d-flex align-items-center gap-2">
                  <div onClick={() => viewModal(item, "userDetail")} className={`wf-action-icons l-blue-bg`}><Eye width="18" /></div>
                    {profile?.systemconfig_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                  </div>
                </td> */}
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
   
    </>
  )
}

export default ClaimListing