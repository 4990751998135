import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllEthnics,
  addEthnic,
  editEthnic,
  deleteEthnic,
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display:"block",
    paddingRight:"0"
  },
};
Modal.setAppElement("#root");

const Ethnics = () => {
  const [ethnicsCount, setethnicsCount] = useState(0);

  const profile=useSelector(getProfile)

  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [ethnics, setethnics] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();

  const [name, setname] = useState("");
  // const [imageName, setimageName] = useState("");

  const [feature, setfeature] = useState("");
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    console.log("feature", feature);
    console.log("type", type);

    if (type == "userDetail" && feature == "add") {
      setId();
      setname();
      setfeature("add")
    }
    if (type == "userDetail" && feature == "edit") {

      setfeature("edit")
      setUserDetail(item);
      setId(item?._id);
    }
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  const Approve= async (id) => {
    try {
      await dispatch(editEthnic({id: id, approved:1})).unwrap();
      
      try {
        Ethnics();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const ethnicDelete = async (id) => {
    try {
      await dispatch(deleteEthnic(id)).unwrap();
      setIsOpen(false);
      try {
        Ethnics();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Ethnics = async () => {
    try {
      setload(true);
      setethnics(null);
      setFilteredData(null);
      const response = await dispatch(getAllEthnics()).unwrap();
      console.log('responsessss', response?.data)
      setethnics(response?.data);
      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((ethnics) => {
        uniqueCreatorsSet.add(ethnics.user_id?._id);
        creatorsData.push(ethnics.user_id);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Ethnics();
    }
    return () => {
      mount = false;
    };
  }, []);

  




  const addandEditEthnic = async (e) => {
    e.preventDefault();

    try {

      // const data = new FormData();

      // data.append("id", id);
      // data.append("name", name);
      // data.append("imageName", imageName);

    
      if (id) {
        await dispatch(editEthnic({id: id, name: name})).unwrap();
        setIsOpen(false);
       
        try {
          Ethnics();
        } catch (rejectedValueOrSerializedError) {
          console.log(rejectedValueOrSerializedError);
        }
     
      Ethnics();
      setId("")     
      } else { 
          await dispatch(addEthnic({id: id, name: name})).unwrap();
          setIsOpen(false);
          
          Ethnics();
           setname("")
          //  setimageName("")
           
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

console.log('ethnics', ethnics)

const [parentFilter, setparentFilter] = useState('');
const [selectedUsers, setselectedUsers] = useState([]);
const [filteredData, setFilteredData] = useState([]);

const applyFilter = () => {
  const filteredResult = ethnics?.filter(item => {
    console.log(item);
    const creatorName = item?.user_id?.name

    console.log(creatorName, "creatorName");

    return (
      (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
    );
  });

  setFilteredData(filteredResult);
  settoggle(false);
};
console.log('selectedUsers', selectedUsers)

const handleStatusChange = (status) => {
  if (selectedUsers.includes(status)) {
    setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
  } else {
    setselectedUsers(prevStatuses => [...prevStatuses, status]);
  }
};

const resetFilter = () => {
  setparentFilter('');
  setselectedUsers([]);
  setFilteredData(ethnics)
  settoggle(false);
};

return (
<>
{profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal fade show wf-modal wf-modal-30`}>

  <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Ethnicity</h1>
            <button type="button" onClick={()=> closeModal()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
              <div class="form-floating wf-input">
                <input type="text" onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="Ethnicity Name" />
                <label for="att-name">Ethnicity Name</label>
              </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditEthnic} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>
          </div>
    </div>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
          <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Ethnicity</h1>
            <button type="button" onClick={()=> closeModal()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
              <div class="form-floating wf-input">
                <input type="text" disabled={profile?.systemconfig_access?.includes("write")?false:true}  onChange={(e)=>setname(e.target.value)} defaultValue={userDetail?.name} class="form-control" id="att-name" placeholder="Ethnicity Name" />
                <label for="att-name">Ethnicity Name</label>
              </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          
          {profile?.systemconfig_access?.includes("write")&&( <button onClick={addandEditEthnic} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Update</button>)}
          </div>
    </div>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Ethnicity</h1>
              <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body mt-0">
                <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Ethnicity?</p>
              </div>
              <div class="modal-footer border-0 justify-content-center gap-2">
                <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                <button onClick={() => ethnicDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
              </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>System Config</a></li>
            <li class="breadcrumb-item active" aria-current="page">Ethnicity</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Ethnicity</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown wf-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>
              <Search />
              <X />
            </span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Created By</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {uniqueCreators.map((creatorId, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                    <label class="btn wf-btn wf-purple-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn wf-purple-btn px-3" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn wf-purple-btn px-3" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn wf-btn wf-purple-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add Ethnics</button>
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
    <p className="font-size-20 pink-color">Ethnicity : {ethnics?.length}</p>
    <div class="table-responsive">
    <table class="table wf-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Ethnicity</th>
      <th scope="col">Created By</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={6}><div style={{ height: '40px' }} className='w-100 shimmer'></div></td></tr>)) :filteredData?.map((item,i)=> (
    <tr key={i}>
      <td>{i+1}</td>
      <td>{item?.name}</td>
      <td>{item?.user_id?.name}</td>
      <td>
        <div className="d-flex align-items-center gap-2">
        {profile?.role=="admin"&&item?.approved==2&&(<div onClick={ () => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {profile?.systemconfig_access?.includes("delete")&&(<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>    

    </>
  )
}

export default Ethnics