import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  storefrontadminapproval,
  getProfile,
  getAllStores,
  FeaturedVendor,
  deleteStore,
  getCities,
  getServices,
  getAllAdmins,
} from "../../store/slices/userSlice";
import moment from "moment";
import Modal from "react-modal";
import {ExternalLink, Pencil, Search, Star, Trash2, X } from "lucide-react";
const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");

const Vendors = () => {
  const profile = useSelector(getProfile);
  const navigate = useNavigate();
  const location = useLocation();

  const [services, setServices] = useState([]);
  const [cities, setcities] = useState([]);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [vendors, setvendors] = useState(null);
  const [admins, setadmins] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);
  const [totalCount, settotalCount] = useState("");
  const [search, setsearch] = useState("");
  const [admin, setadmin] = useState("");
  const [category, setcategory] = useState("");
  const [city, setcity] = useState("");
  const [date, setdate] = useState("");
  const [country, setcountry] = useState("66596fcc9079984b6b40ad6e");

  const [showApproveIcon, setShowApproveIcon] = useState(true);
  const Approve = async (e,id) => {
    try {
      const items = vendors.map((item) =>
        item._id === id ? { ...item, approved: e.target.checked ? 1 : 0 } : item
      );
      setvendors(items);
      await dispatch(storefrontadminapproval({ event_id: id, approved: e.target.checked ? 1 : 0, type: "approve" })).unwrap();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const storeDelete = async (id) => {
    try {
      await dispatch(deleteStore(id)).unwrap();
      setIsOpen(false);
      setvendors((prevCities) => prevCities?.filter(store => store?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError, "error");
    }
  };

  const getCity = async () => {
    try {
      setcities(null)
      const response = await dispatch(getCities()).unwrap();
      setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError, "error");
    }
  };

  const Admins = async () => {
    try {
      setadmins(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setadmins(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);


  const Vendors = async () => {
    try {
      setload(true);
      setvendors(null);
      const response = await dispatch(getAllStores({page: currentPage, search: search, admin: admin, category: category, city: city, date: date})).unwrap();
      setvendors(response?.data);
      settotalCount(response?.totalCount);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Vendors();
  }, [currentPage, search, admin, category, city, date]);

  const handleFeatureVendor = async (itemm,value) => {
    try {
      await dispatch(FeaturedVendor({ id: itemm._id, is_featured: value==1?0:1 })).unwrap();
      const items = vendors.map((item) =>
        item._id === itemm._id ? { ...item, is_featured: value==1?0:1 } : item
      );
      setvendors(items);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  

  const [storeNameFilter, setstoreNameFilter] = useState('');
  const [selectcategory, setselectcategory] = useState('');
  const [selectcity, setselectcity] = useState('');
  const [dateFilter, setdateFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState('');

  const applyFilter = () => {
    setsearch(storeNameFilter)
    setadmin(selectedUsers)
    setcategory(selectcategory)
    setcity(selectcity)
    setdate(dateFilter)
    navigate(`${location.pathname}`);
    settoggle(false);
  };

  const resetFilter = () => {
    setselectcategory('');
    setselectcity('');
    setdateFilter('');
    setstoreNameFilter("")
    setselectedUsers('');
    setsearch('')
    setadmin('')
    setcity('')
    setcategory('')
    setdate('')
    settoggle(false);
  };

  useEffect(() => {   
    Services();
    getCity();
    Admins();
  }, []);



  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  const renderPaginationLinks = () => {
    const pages = [];
    const maxPagesToShow = 5; 
    if (totalPages <= maxPagesToShow) {
      for (let page = 1; page <= totalPages; page++) {
        pages.push(renderPageLink(page));
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
      if (endPage === totalPages) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
      pages.push(renderPageLink(1));
      if (startPage > 2) {
        pages.push(renderEllipsis());
      }
      for (let page = startPage === 1 ? 2 : startPage; page <= endPage; page++) {
        pages.push(renderPageLink(page));
      }
      if (endPage < totalPages - 1) {
        pages.push(renderEllipsis());
      }
      if (endPage !== totalPages) {
        pages.push(renderPageLink(totalPages));
      }
    }
    return pages;
  };
  
  const renderPageLink = (page) => (
    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
      <a className="page-link" onClick={() => handlePageChange(page)}>
        {page}
      </a>
    </li>
  );
  
  const renderEllipsis = () => (
    <li key="ellipsis" className="page-item disabled">
      <span className="page-link">...</span>
    </li>
  );


return (
  <>
  {profile?.stores_access?.includes("write") && (
    <Modal closeTimeoutMS={500} isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Change Password" className={`modal fade show wf-modal wf-modal-30`}>
      <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
        {modalType == "delete" ? (
          <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Vendor</h1>
          <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body mt-0">
            <p class="font-size-14 color-black mb-0">Are you sure you want to delete this Vendor?</p>
          </div>
          <div class="modal-footer border-0 justify-content-center gap-2">
            <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
            <button onClick={() => storeDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
          </div>
      </div>
        ) : null}
      </div>
    </Modal>
  )}

  <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item active" aria-current="page">Vendors</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Vendors</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
      <div class="dropdown wf-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>{toggle ? <X /> : <Search />}</span>
          </a>
          
          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
          <div class="form-floating wf-input mb-3">
              <input type="text" class="form-control" id="store-name-filter" placeholder="Store Name" onChange={(e) => setstoreNameFilter(e.target.value)} value={storeNameFilter}/>
              <label for="store-name-filter">Store Name</label>
            </div>
          <div class="dropdown wf-select-dropdown mb-3">
              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectcategory?.length > 0 ? selectcategory : "Category"}
              </a>
              <ul class="dropdown-menu">
                {services?.length > 0 && services?.map((c, i) => (
                  <li key={i}><a onClick={() => setselectcategory(c?._id)} className="dropdown-item">
                    {c?.name}
                  </a></li>
                ))}
              </ul>
            </div>

            <div class="dropdown wf-select-dropdown mb-3">
              <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {selectcity?.length > 0 ? cities?.find(item => item?._id == selectcity).name  : "City"}
              </a>
              <ul class="dropdown-menu">
                {cities?.length > 0 && cities?.filter(item => item?.country_id === country)?.map((c, i) => (
                  <li key={i}><a onClick={() => setselectcity(c?._id)} className="dropdown-item">
                    {c?.name}
                  </a></li>
                ))}
              </ul>
            </div>
            <div class="form-floating wf-input mb-3">
                <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter}/>
                <label for="date-filter">Date</label>
              </div>

            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Author</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {admins?.map((data, i) => (
                  <>
                    <input type="radio" class="btn-check" checked={selectedUsers.includes(data?._id)} onChange={() => setselectedUsers(data?._id)} id={`created-by-${data?._id}`} value={data?._id} />
                    <label class="btn wf-select-btn" for={`created-by-${data?._id}`}>{data?.name}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
      {profile?.stores_access?.includes("write") && (
        <Link class="btn wf-btn wf-purple-btn px-3" to="/quick-add-vendor">Quick Add</Link>)}
        {profile?.stores_access?.includes("write") && (
        <Link class="btn wf-btn wf-purple-btn px-3" to="/add-vendor">Add Vendor</Link>)}
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
      <p className="font-size-20 pink-color">Vendors : {totalCount}</p>
      <div class="table-responsive">
        <table class="table wf-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Store Name</th>
              <th scope="col">Category</th>
              <th scope="col">City</th>
              <th scope="col">Date</th>
              <th scope="col">Author</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : vendors?.map((item, i) => (
              <tr key={i}>
                <td>{i + startIndex + 1}</td>
                <td>{item?.store_name}</td>
                <td>{item?.prefered_services[0]?.name}</td>
                <td>{item?.cities[0]?.name}</td>
                <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                <td>{item?.added_by?.name}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                  {profile?.stores_access?.includes("write") ? (
                    <div onClick={() => handleFeatureVendor(item,item?.is_featured)} className={`wf-action-icons l-blue-bg`}>
                      {item?.is_featured == 1 ? (<Star fill="#804099" />) : (<Star  />)}
                    </div>) : (null)}
                    <a href={`https://www.shaditayari.pk/supplier-details/${item?.slug}`} target="_blank"  className={`wf-action-icons l-blue-bg`}><ExternalLink width="18" /></a>
                    <Link to={`/edit-vendor/${item._id}`}  className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></Link>
                    {profile?.stores_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                    {profile?.role == "admin"&&(<div class="form-check form-switch wf-switch">
                      <input checked={item?.approved == 1} onChange={(e) => Approve(e,item?._id)} class="form-check-input" type="checkbox" role="switch" id="approve-disapprove"/>
                    </div>)}
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
      {totalCount > 50 &&
      <div className="wf-pagination" aria-label="Page navigation example">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
          <a className="page-link" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {renderPaginationLinks()}
        <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
          <a className="page-link" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </div>}
    </div>
  </div>
    </>
  );
};

export default Vendors;