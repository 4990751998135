import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllCategories,
  getAllAttributes,
  addAttribute,
  editAttribute,
  deleteAttribute,
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Search, ThumbsUp, Trash2, X } from "lucide-react";
import { Pencil } from "lucide-react";


const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");

const Attributes = () => {

  const profile = useSelector(getProfile)
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [categories, setcategories] = useState(null);
  const [attributes, setattributes] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [parent_id, setparent_id] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [imageName, setimageName] = useState("");
  const [feature, setfeature] = useState("");
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    console.log("feature", feature);
    console.log("type", type);

    if (type == "userDetail" && feature == "add") {
      setId();
      setname();
      setparent_id();
      setcategory_id();
      setimageName();
      setfeature("add")
    }
    if (type == "userDetail" && feature == "edit") {

      setfeature("edit")
      setUserDetail(item);
      setname(item?.name);
      setparent_id(item?.parent_id?._id);
      setcategory_id(item?.category_id?._id);
      setimageName(item?.imageName);
      setId(item?._id);
    }
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };
  // function viewModal(item, type) {
  //   setIsOpen(true);
  //   if (type == "userDetail") {
  //     setUserDetail(item);
  //     setId(item?._id);
  //   } else if (type == "delete") {
  //     setId(item);
  //   }
  //   setModalType(type);
  //   setIsOpen(true);
  // }


  function closeModal() {
    setIsOpen(false);
    setUserDetail()
    setId("")
  }

  const attributeDelete = async (id) => {
    try {
      await dispatch(deleteAttribute(id)).unwrap();
      setIsOpen(false);
      try {
        Attributes();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Attributes = async () => {
    try {
      setload(true);
      setattributes(null);
      setFilteredData(null);
      const response = await dispatch(getAllAttributes()).unwrap();
      console.log('responsessss', response?.data)
      setattributes(response?.data);
      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((attributes) => {
        uniqueCreatorsSet.add(attributes.user_id?._id);
        creatorsData.push(attributes.user_id);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Categories = async () => {
    try {
      
      setcategories(null);
      const response = await dispatch(getAllCategories()).unwrap();
      console.log('responsessss', response?.data)
      setcategories(response?.data);
     
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Attributes();
      Categories();
    }
    return () => {
      mount = false;
    };
  }, []);


  const addandEditCategory = async (e) => {
    e.preventDefault();

    try {
      console.log('category_id', category_id)
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("parent_id", parent_id?parent_id:null);
      data.append("category_id", category_id?category_id:null);
      // const modifiedImageName = imageName && typeof imageName === 'string'
      // ? imageName.replace(/\s+/g, '-').toLowerCase()
      // : imageName;
    
      // data.append("imageName", modifiedImageName);
      data.append("imageName", imageName);
   
      if (id) {
        console.log([...data]);
        await dispatch(editAttribute(data)).unwrap();
        setIsOpen(false);
     
      Attributes();
      setId("")     
      } else { 
        console.log([...data]);
          await dispatch(addAttribute(data)).unwrap();
          setIsOpen(false);

          Attributes();
           setname("")
           setparent_id("")
           setcategory_id("")
           setimageName("")

           
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const generatedId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  // const addandEditCategory = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     const data = new FormData();
  //     data.append("id", id);
  //     data.append("name", name);
  //     data.append("parent_id", parent_id ? parent_id : null);
  //     data.append("category_id", category_id ? category_id : null);
  //     data.append("imageName", imageName);
  
  //     let updatedAttributes;
  
  //     if (id) {
  //       // Editing an existing attribute
  //       await dispatch(editAttribute(data)).unwrap();
  
  //       // Update the state locally
  //       updatedAttributes = attributes.map(attr =>
  //         attr.id === id ? { ...attr, name, parent_id, category_id, imageName } : attr
  //       );
  //     } else {
  //       // Adding a new attribute
  //       await dispatch(addAttribute(data)).unwrap();
  
  //       // Update the state locally
  //       const newAttribute = { id: generatedId(), name, parent_id, category_id, imageName };
  //       updatedAttributes = [...attributes, newAttribute];
  //     }
  
  //     // Update the state
  //     setattributes(updatedAttributes);
  //     setIsOpen(false);
  //   } catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //   }
  // };
  
  
  

  const Approve= async (id) => {
    try {
      await dispatch(editAttribute({id: id, approved:1})).unwrap();
      
      try {
        Attributes();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [parentFilter, setparentFilter] = useState('');
  const [catFilter, setcatFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = attributes?.filter(item => {
      console.log(item); // Log the item to the console for inspection

      const userName = item?.parent_id?.name.toLowerCase();
      const catName = item?.category_id?.name.toLowerCase();
      const creatorName = item?.user_id?.name

      console.log(creatorName, "creatorName");

      return (
        (parentFilter === '' || userName.includes(parentFilter.toLowerCase())) &&
        (catFilter === '' || catName.includes(catFilter.toLowerCase())) &&
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };
  console.log('selectedUsers', selectedUsers)

  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setparentFilter('');
    setselectedUsers([]);
    setFilteredData(attributes)
    settoggle(false);
  };

  useEffect(() => {
    setparent_id(userDetail?.parent_id?._id)
    setcategory_id(userDetail?.category_id?._id)
}, [userDetail])
  
    
return (
<>

{profile?.systemconfig_access?.includes("write")&&(
<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Add/Edit/Delete"
className={`modal fade show wf-modal ${modalType == "delete" && 'wf-modal-30'}`}
>
  <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
  {modalType == "userDetail"  && feature == "add" ? (
    <>
    <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Attribute / Sub-Attribute</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating wf-input">
                      <input type="text" onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="Attribute / Sub-Attribute Name" />
                      <label for="att-name">Attribute / Sub-Attribute Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown wf-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {parent_id ? attributes.find(user => user?._id === parent_id)?.name : "Parent Attribute (For Sub-Attributes)"}
                      </a>
                      <ul class="dropdown-menu">
                      {attributes?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setparent_id(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="dropdown wf-select-dropdown mb-3">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {category_id ? categories?.filter((ci) => ci?.parent_id==null)?.find(user => user?._id === category_id)?.name : "Parent Attribute (For Sub-Attributes)"}
                      </a>
                      <ul class="dropdown-menu">
                      {categories?.filter((ci) => ci?.parent_id==null)?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setcategory_id(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                </div>
                <div class="form-floating wf-input">
                      <input type="file" onChange={(e)=>setimageName(e.target.files[0])} class="form-control" id="att-icon" placeholder="Attribute / Sub-Attribute Icon" />
                      <label for="att-icon">Attribute / Sub-Attribute Icon</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditCategory} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>
          </div>
    </div>
    </> ) : modalType == "userDetail" && feature == "edit" ? ( 
      <>
      <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Edit Attribute / Sub-Attribute</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating wf-input">
                      <input type="text" disabled={profile?.systemconfig_access?.includes("write")?false:true} defaultValue={userDetail?.name} onChange={(e)=>setname(e.target.value)} class="form-control" id="att-name" placeholder="Attribute / Sub-Attribute Name" />
                      <label for="att-name">Attribute / Sub-Attribute Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown wf-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {parent_id ? attributes.find(user => user?._id === parent_id)?.name : "Parent Attribute (For Sub-Attributes)"}
                      </a>
                      <ul class="dropdown-menu">
                      <li><a onClick={(e)=>setparent_id("")} class="dropdown-item">Parent Attribute</a></li>
                      {attributes?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setparent_id(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="dropdown wf-select-dropdown mb-3">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {category_id ? categories?.filter((ci) => ci?.parent_id==null)?.find(user => user?._id === category_id)?.name : "Parent Attribute (For Sub-Attributes)"}
                      </a>
                      <ul class="dropdown-menu">
                      {categories?.filter((ci) => ci?.parent_id==null)?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setcategory_id(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                </div>
                <div class="form-floating wf-input">
                      <input type="file" disabled={profile?.systemconfig_access?.includes("write")?false:true} 
                      onChange={(e)=>setimageName(e.target.files[0])}
                       class="form-control" id="att-icon" placeholder="Attribute / Sub-Attribute Icon" />
                      <label for="att-icon">Attribute / Sub-Attribute Icon</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
        {profile?.systemconfig_access?.includes("write")&&(<button onClick={addandEditCategory} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>)}
          </div>
    </div>
      
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Attribute/Sub-Attribute</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-0">
              <p class="font-size-14 color-black mb-0">Are you sure you want to delete this attribute/sub-attribute?</p>
            </div>
            <div class="modal-footer border-0 justify-content-center gap-2">
              <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
              <button onClick={() => attributeDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
            </div>
          </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>)}

<div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>System Config</a></li>
            <li class="breadcrumb-item active" aria-current="page">Attributes</li>
        </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Attributes</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">
        <div class="dropdown wf-search-dropdown">
          <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
            <span>Filter and Search</span>
            <span>
              <Search />
              <X />
            </span>
          </a>

          <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Attribute</label>
              <div class="col-sm-10">
                <input type="text" onChange={e => setparentFilter(e.target.value)} value={parentFilter} placeholder="Filter by Parent Attribute" class="form-control" id="inputname" />
              </div>
            </div>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Category</label>
              <div class="col-sm-10">
                <input type="text" onChange={e => setcatFilter(e.target.value)} value={catFilter} placeholder="Filter by Category" class="form-control" id="inputname" />
              </div>
            </div>
            <div class="row mb-3 sp-input-filter">
              <label for="inputname" class="col-sm-2 col-form-label">Author</label>
              <div class="col-sm-10 d-flex gap-2 flex-wrap">
                {uniqueCreators.map((creatorId, i) => (
                  <>
                    <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                    <label class="btn wf-btn wf-purple-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                  </>
                ))}

              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end">
              <button class="btn wf-btn wf-purple-btn px-3" onClick={applyFilter}>Search</button>
              <button class="btn wf-btn wf-purple-btn px-3" onClick={resetFilter}>Reset</button>
            </div>
          </ul>
        </div>
      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn wf-btn wf-purple-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add Attribute</button>
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
    <p className="font-size-20 pink-color">Attributes / Sub-Attributes : {filteredData?.length}</p>
    <div class="table-responsive">
    <table class="table wf-table table-borderless table-striped">
  <thead>
    <tr>
      <th scope="col">S.No</th>
      <th scope="col">Attribute / Sub-Attribute Name</th>
      <th scope="col">Parent Attribute</th>
      <th scope="col">Category</th>
      <th scope="col">Icon</th>
      <th scope="col">Author</th>
      <th style={{width:'100px'}} scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  {load ? new Array(223).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item,i)=> (
    <tr key={i}>
      <td style={{verticalAlign: 'middle'}}>{i+1}</td>
      <td style={{verticalAlign: 'middle'}}>{item?.name}</td>
      <td style={{verticalAlign: 'middle'}}>{item?.parent_id?.name}</td>
      <td style={{verticalAlign: 'middle'}}>{item?.category_id?.name}</td>
      <td style={{verticalAlign: 'middle'}}><img src={`${process.env.REACT_APP_IMGURL}${item?.imageName}`} width={50} alt="" /></td>
      <td style={{verticalAlign: 'middle'}}>{item?.updatedByUserId?.name}</td>
      <td style={{verticalAlign: 'middle'}}>
        <div className="d-flex align-items-center gap-2">
        {profile?.role=="admin"&&item?.approved==2&&(<div onClick={ () => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
            <div onClick={() => viewModal(item, "userDetail","edit")} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
            {profile?.systemconfig_access?.includes("delete")&&(<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
        </div>
      </td>
      </tr>))}
  </tbody>
    </table>
    </div>
    </div>
</div>
    
    </>
  )
}

export default Attributes