import React, { useEffect, useState, useContext } from 'react'
import { context } from '../../context/context';
import {  useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/slices/userSlice"
import { AlignJustify, ChevronDown, ChevronUp } from 'lucide-react';


const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const profile = useSelector(getProfile)  
    const { pathname } = location;
    const { toggleButton, SetToggleButton } = useContext(context);
    const [togglemenu, settogglemenu] = useState(false);

    const OpenDropdown = () => {
        return  pathname === "/categories" ||
        pathname === "/attributes" ||
        pathname === "/areas" ||
        pathname === "/cities" ||
        pathname === "/ethnics" ||
        pathname === "/themes" ||
        pathname === "/questions" ||
        pathname === "/religions";
    };
    useEffect(() => {
        settogglemenu(OpenDropdown());
    }, [pathname]);


    const [togglemenu2, settogglemenu2] = useState(false);
    const OpenDropdown2 = () => {
        return  pathname === "/tasks" ||
        pathname === "/add-task";
    };
    useEffect(() => {
        settogglemenu2(OpenDropdown2());
    }, [pathname]);

    const [togglemenu3, settogglemenu3] = useState(false);
    const OpenDropdown3 = () => {
        return  pathname === "/admins" ||
        pathname === "/add-admin";
    };
    useEffect(() => {
        settogglemenu3(OpenDropdown3());
    }, [pathname]);


  return (
    <>
    <div className={toggleButton ? "sidebar close" : "sidebar"}>
        <div class="logo-details">
            <AlignJustify onClick={() => SetToggleButton((prev) => !prev)} stroke='var(--color4)' strokeWidth={1} />
        </div>
        <ul class="nav-links">
        <li className={`${location?.pathname === "/" ? "active" : ""}`}>
            <div class="icon-link">
                <Link to="/">
                    <img src='../assets/images/dashboard-icon.svg' />
                    <span class="link_name">Dashboard</span>
                </Link>
            </div>
        </li>

        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/categories" || location?.pathname === "/attributes" || location?.pathname === "/areas" || location?.pathname === "/cities" 
                || location?.pathname === "/countries" || location?.pathname === "/ethnics" || location?.pathname === "/themes" || location?.pathname === "/questions"
                || location?.pathname === "/religions" ? "active" : ""} ${togglemenu ? "showMenu" : ""}`}>
                <div class="icon-link" onClick={() => settogglemenu(!togglemenu)}>
                    <Link>
                        <img src='../assets/images/system-config-icon.svg' />
                        <span class="link_name">System Config</span>
                    </Link>
                    <span class={`cursor-pointer`}>
                        {togglemenu ? <ChevronUp /> : <ChevronDown />}
                    </span>
                </div>
                <ul class={`sub-menu ${togglemenu && "active"}`}>
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/categories" ? "active" : ""}`} to="/categories">
                                Categories
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/attributes" ? "active" : ""}`} to="/attributes">
                                Attributes
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/areas" ? "active" : ""}`} to="/areas">
                                Areas
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/cities" ? "active" : ""}`} to="/cities">
                                Cities
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/ethnics" ? "active" : ""}`} to="/ethnics">
                                Ethnicity
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/themes" ? "active" : ""}`} to="/themes">
                                Themes
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/questions" ? "active" : ""}`} to="/questions">
                                Questions
                            </Link>
                        </li>
                    )}
                    {profile?.systemconfig_access?.length > 0 && (
                        <li>
                            <Link class={`${location?.pathname === "/religions" ? "active" : ""}`} to="/religions">
                                Religions
                            </Link>
                        </li>
                    )}
                </ul>
            </li>
        )}

        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/tasks" || location?.pathname === "/add-task" ? "active" : ""} ${togglemenu2 ? "showMenu" : ""}`}>
                <div class="icon-link" onClick={() => settogglemenu2(!togglemenu2)}>
                    <Link>
                    <img src='../assets/images/task-icon.svg' />
                        <span class="link_name">Tasks</span>
                    </Link>
                    <span class={`cursor-pointer`}>
                        {togglemenu2 ? <ChevronUp /> : <ChevronDown />}
                    </span>
                </div>
                <ul class={`sub-menu ${togglemenu2 && "active"}`}>
                    <li>
                        <Link class={`${location?.pathname === "/tasks" ? "active" : ""}`} to="/tasks">
                            All Tasks
                        </Link>
                    </li>
                    <li>
                        <Link class={`${location?.pathname === "/add-task" ? "active" : ""}`} to="/add-task">
                            Add Task
                        </Link>
                    </li>
                </ul>
            </li>
        )}

        {profile?.role === "admin" && (
            <li className={`${location?.pathname === "/admins" || location?.pathname === "/add-admin" ? "active" : ""} ${togglemenu3 ? "showMenu" : ""}`}>
                <div class="icon-link" onClick={() => settogglemenu3(!togglemenu3)}>
                    <Link>
                    <img src='../assets/images/admin-icon.svg' />
                    <span class="link_name">Admin</span>
                    </Link>
                    <span class={`cursor-pointer`}>
                        {togglemenu3 ? <ChevronUp /> : <ChevronDown />}
                        </span>
                </div>
                <ul class={`sub-menu ${togglemenu3 && "active"}`}>
                    <li>
                        <Link class={`${location?.pathname === "/admins" ? "active" : ""}`} to="/admins">
                            All Admins
                        </Link>
                    </li>
                    <li>
                        <Link class={`${location?.pathname === "/add-admin" ? "active" : ""}`} to="/add-admin">
                            Add Admins
                        </Link>
                    </li>
                </ul>
            </li>
        )}

        {profile?.stores_access?.length > 0 && (
            <li className={`${location?.pathname === "/vendors" || location?.pathname === "/add-vendor" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/vendors">
                    <img src='../assets/images/vendor-icon.svg' />
                        <span class="link_name">Vendors</span>
                    </Link>
                </div>
            </li>
        )}

        {profile?.users_access?.length > 0 && (
            <li className={`${location?.pathname === "/users" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/users">
                    <img src='../assets/images/users-icon.svg' />
                        <span class="link_name">Users</span>
                    </Link>
                </div>
            </li>
        )}

        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/contact-form" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/contact-form">
                    <img src='../assets/images/contact-icon.svg' />
                        <span class="link_name">Contact Queries</span>
                    </Link>
                </div>
            </li>
        )}

        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/customer-inquiries" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/customer-inquiries">
                    <img src='../assets/images/users-icon.svg' />
                        <span class="link_name">Customer Inquiries</span>
                    </Link>
                </div>
            </li>
        )}
        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/claim-listings" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/claim-listings">
                    <img src='../assets/images/contact-icon.svg' />
                        <span class="link_name">Claim Listing</span>
                    </Link>
                </div>
            </li>
        )}
        {profile?.systemconfig_access?.length > 0 && (
            <li className={`${location?.pathname === "/report-listings" ? "active" : ""}`}>
                <div class="icon-link">
                    <Link to="/report-listings">
                    <img src='../assets/images/contact-icon.svg' />
                        <span class="link_name">Report Listing</span>
                    </Link>
                </div>
            </li>
        )}

        </ul>
    </div>
  </>
  
  )
}

export default Sidebar