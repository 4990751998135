import axios from "axios";
import {
  privacy,
  terms,
  dashboard,
  addManualBookings,
  rejectRequest,
  rejectOffer,
  allRead,
  saveAttachment,
  getChatMembers,
  deleteStore,
  signinAdmin,
  storefrontadminapproval,
  getAllUsers,
  getAllStores,
  getStoreDetails,
  getVendorList,
  getvendorquestionsadmin,
  AllQuestions,
  editstorefrontadmin,
  getServices,
  getStore,
  getClaimStores,
  getReportStores,
  getBlogDetails,
  getfaq,
  getAllAdmins,
  addAdmin,
  editAdmin,
  deleteAdmin,
  getAllCities,
  getAllAreas,
  getAllAttributes,
  getAllThemes,
  getAllReligions,
  getAllCountries,
  addTheme,
  addReligion,
  addCountry,
  editTheme,
  editCountry,
  editReligion,
  deleteTheme,
  deleteCountry,
  deleteReligion,
  getAllContactQueries,
  getAllBlogs,
  getmydealsadmin,
  updatePasswordAdmin,
  editQuestion,
  deleteQuestion,
  addCity,
  editCity,
  deleteCity,
  getEthnics,
  addEthnic,
  editEthnic,
  deleteEthnic,
  getSubAttribute,
  deleteContactQueries,
  getAllTags,
  addCategory,
  editCategory,
  deleteCategory,
  addArea,
  editArea,
  deleteArea,
  addAttribute,
  editAttribute,
  deleteAttribute,
  addSubService,
  getServiceTypes,
  getAllCategories,
  getAllEthnics,
  getCities,
  deleteSubService,
  addQuestion,
  getAttribute,
  addBlog,
  editBlog,
  deleteBlog,
  deleteEvent,
  updatePrivacy,
  updateTerms,
  adminLogout,
  deleteAccount,
  getTask,
  addTask,
  editTask,
  getChats
} from "../slices/userSlice";
import toast from "react-hot-toast";


export const extraReducers = (builder) => {
  builder

    // signIn Admin
    .addCase(signinAdmin.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(signinAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      toast.success(action.payload.message)

      localStorage.setItem("user", JSON.stringify(action.payload.data));
      state.user = action.payload.data;
      console.log(state.user);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.data.token}`;

      state.error = null;
    })
    .addCase(signinAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addCountry
    .addCase(addCountry.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addReligion
    .addCase(addReligion.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addReligion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addReligion.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addAdmin
    .addCase(addAdmin.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addTheme
    .addCase(addTheme.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addTheme.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addTheme.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // Add addCity
    .addCase(addCity.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addCity.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addCity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addCategory
    .addCase(addCategory.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addCategory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addCategory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addArea
    .addCase(addArea.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addArea.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addArea.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addAttribute
    .addCase(addAttribute.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addSubService
    .addCase(addSubService.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addSubService.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addSubService.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editstorefrontadmin
    .addCase(editstorefrontadmin.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editstorefrontadmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editstorefrontadmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addQuestion
    .addCase(addQuestion.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addQuestion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addQuestion.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addEthnic
    .addCase(addEthnic.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addEthnic.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addEthnic.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addBlog
    .addCase(addBlog.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addBlog.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addBlog.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editReligion
    .addCase(editReligion.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editReligion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editReligion.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editCountry
    .addCase(editCountry.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editTheme
    .addCase(editTheme.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editTheme.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editTheme.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editAdmin
    .addCase(editAdmin.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editCity
    .addCase(editCity.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editCity.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editCity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editEthnic
    .addCase(editEthnic.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editEthnic.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editEthnic.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // Edit editQuestion
    .addCase(editQuestion.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editQuestion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editQuestion.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editCategory
    .addCase(editCategory.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editCategory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editCategory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // editArea
    .addCase(editArea.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editArea.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editArea.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // getServices
    .addCase(getServices.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getServices.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getServices.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getfaq
    .addCase(getfaq.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getfaq.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getfaq.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getStore
    .addCase(getStore.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getStore.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getStore.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // editAttribute
    .addCase(editAttribute.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // addManualBookings
    .addCase(addManualBookings.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addManualBookings.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(addManualBookings.rejected, (state, action) => {
      console.log('action?.payload?', action?.payload?.data?.remainingAmount)
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    // rejectRequest
    .addCase(rejectRequest.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(rejectRequest.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(rejectRequest.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

      // rejectOffer
      .addCase(rejectOffer.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(rejectOffer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        toast.success(action?.payload?.message);
      })
      .addCase(rejectOffer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action?.payload?.message;
        toast.error(action?.payload?.message);
      })

       // allRead
    .addCase(allRead.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(allRead.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(allRead.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

     // saveAttachment
     .addCase(saveAttachment.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(saveAttachment.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action?.payload?.message);
    })
    .addCase(saveAttachment.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
      toast.error(action?.payload?.message);
    })

    
    // getChatMembers
    .addCase(getChatMembers.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getChatMembers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getChatMembers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // editBlog
    .addCase(editBlog.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editBlog.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editBlog.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteReligion
    .addCase(deleteReligion.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteReligion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteReligion.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteCountry
    .addCase(deleteCountry.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteCountry.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteCountry.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteTheme
    .addCase(deleteTheme.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteTheme.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteTheme.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteAdmin
    .addCase(deleteAdmin.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteQuestion
    .addCase(deleteQuestion.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteQuestion.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteQuestion.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // getSubAttribute
    .addCase(getSubAttribute.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getSubAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getSubAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getCities
    .addCase(getCities.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getCities.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // deleteStore
    .addCase(deleteStore.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteStore.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteStore.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteCity
    .addCase(deleteCity.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteCity.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteCity.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteEthnic
    .addCase(deleteEthnic.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteEthnic.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteEthnic.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteContactQueries
    .addCase(deleteContactQueries.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteContactQueries.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteContactQueries.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // getAllTags
    .addCase(getAllTags.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getAllTags.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(getAllTags.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // getBlogDetails
    .addCase(getBlogDetails.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(getBlogDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(getBlogDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // Delete Service
    .addCase(deleteCategory.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteArea
    .addCase(deleteArea.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteArea.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteArea.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteAttribute
    .addCase(deleteAttribute.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteSubService
    .addCase(deleteSubService.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteSubService.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteSubService.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteEvent
    .addCase(deleteEvent.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteEvent.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteEvent.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteAccount
    .addCase(deleteAccount.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteAccount.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteAccount.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // deleteBlog
    .addCase(deleteBlog.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(deleteBlog.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(deleteBlog.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // getAllCategories
    .addCase(getAllCategories.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllCategories.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllCategories.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllEthnics
    .addCase(getAllEthnics.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllEthnics.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllEthnics.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllUsers
    .addCase(getAllUsers.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllUsers.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllUsers.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAttribute
    .addCase(getAttribute.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAttribute.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAttribute.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllStores
    .addCase(getAllStores.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllStores.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllStores.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getStoreDetails
    .addCase(getStoreDetails.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getStoreDetails.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getStoreDetails.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getReportStores
    .addCase(getReportStores.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getReportStores.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getReportStores.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getClaimStores
    .addCase(getClaimStores.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getClaimStores.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getClaimStores.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllAdmins
    .addCase(getAllAdmins.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllAdmins.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllAdmins.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllCities
    .addCase(getAllCities.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllCities.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllCities.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getVendorList
    .addCase(getVendorList.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getVendorList.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getVendorList.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getvendorquestionsadmin
    .addCase(getvendorquestionsadmin.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getvendorquestionsadmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getvendorquestionsadmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // AllQuestions
    .addCase(AllQuestions.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(AllQuestions.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(AllQuestions.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllAreas
    .addCase(getAllAreas.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllAreas.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllAreas.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getServiceTypes
    .addCase(getServiceTypes.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getServiceTypes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getServiceTypes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })

    // getAllAttributes
    .addCase(getAllAttributes.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllAttributes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllAttributes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllCountries
    .addCase(getAllCountries.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllCountries.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllCountries.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllReligions
    .addCase(getAllReligions.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllReligions.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllReligions.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    
    // getEthnics
    .addCase(getEthnics.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getEthnics.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;

    })
    .addCase(getEthnics.rejected, (state, action) => {
      state.status = "failed";
      state.error = action?.payload?.message;
    })


    // getAllThemes
    .addCase(getAllThemes.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllThemes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllThemes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllContactQueries
    .addCase(getAllContactQueries.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllContactQueries.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllContactQueries.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getmydealsadmin
    .addCase(getmydealsadmin.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getmydealsadmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getmydealsadmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // getAllBlogs
    .addCase(getAllBlogs.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getAllBlogs.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    })
    .addCase(getAllBlogs.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // updatePasswordAdmin
    .addCase(updatePasswordAdmin.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updatePasswordAdmin.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(updatePasswordAdmin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // adminLogout
    .addCase(adminLogout.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(adminLogout.fulfilled, (state, action) => {
      toast.success(action.payload.message);
      localStorage.clear();
      state.status = "succeeded";
      state.user = null;
      state.error = null;
      state.TcPp = null;
      state.categories = null;
      state.dashboard = null;
    })
    .addCase(adminLogout.rejected, (state, action) => {
      toast.error(action.payload.message);
      state.status = "failed";
      state.error = action.payload.message;
    })

    // Get TcPp
    .addCase(terms.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(terms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.terms = action.payload;
    })
    .addCase(terms.rejected, (state, action) => {
      console.log("action.payload :>> ", action.payload);
      state.status = "failed";
      state.error = action.payload.message;
    })
    // Get TcPp
    .addCase(privacy.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(privacy.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.privacy = action.payload;
    })
    .addCase(privacy.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })

    // Update TcPp
    .addCase(updateTerms.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updateTerms.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.privacy = action.payload;
      toast.success(action.payload.message);
    })
    .addCase(updateTerms.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })
    // Update TcPp
    .addCase(updatePrivacy.pending, (state, action) => {
      state.status = "loading";
    })
    .addCase(updatePrivacy.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.terms = action.payload;
      toast.success(action.payload.message);
    })
    .addCase(updatePrivacy.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    })


        // storefrontadminapproval
        .addCase(storefrontadminapproval.pending, (state, action) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(storefrontadminapproval.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.error = null;
          toast.success(action?.payload?.message);
        })
        .addCase(storefrontadminapproval.rejected, (state, action) => {
          state.status = "failed";
          state.error = action?.payload?.message;
          toast.error(action?.payload?.message);
        })

        
    // Edit Task
    .addCase(editTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(editTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(editTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    .addCase(addTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(addTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      toast.success(action.payload.message);
    })
    .addCase(addTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
      toast.error(action.payload.message);
    })

    // getTask
    .addCase(getTask.pending, (state, action) => {
      state.status = "loading";
      state.error = null;
    })
    .addCase(getTask.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
      state.allTasks = action.payload.data;
    })
    .addCase(getTask.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    });
};
