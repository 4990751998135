import React, { useEffect, useState } from "react";
import { ArrowLeft, Pencil } from "lucide-react";
import { Trash2, Plus } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  addTask as addNewTask,
  editTask,
} from "../../store/slices/userSlice";
import toast from 'react-hot-toast';

const AddTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [id, setId] = useState("");
  const [categories, setcategories] = useState(null);
  const [taskname, settaskname] = useState("");
  const [category, setcategory] = useState("");
  const [description, setdescription] = useState("");
  const [options, setoptions] = useState([]);
  const [prevoptions, setprevoptions] = useState([]);

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getAllCategories()).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
      Categories();
    if (location?.state?._id) {
      setId(location?.state?._id?._id);
      settaskname(location?.state?._id?.title);
      setcategory(location?.state?._id?.category_id?._id);
      setdescription(location?.state?._id?.description);
      setprevoptions(location?.state?._id?.subtasks);
    } else {
      setoptions([{ name: "", days: "" }]);
    }
  }, []);

  const addMoreOptions = () => {
    setoptions([...options, { name: "", days: "" }]);
  };

  const addandEdittask = async (e) => {
    e.preventDefault();

    try {
      console.log("####", id, "#########################");
      if (id) {
        if (options[options?.length - 1]?.name == ""||options[options?.length - 1]?.days == "") {
          toast.error("Please fill out the required fields");
        } else {
          console.log(
            "#### options",
            taskname,
            category,
            description,
            options,
            "#########################"
          );
          const payload = {
            id: id,
            taskname: taskname,
            category: category,
            description: description,
            subtasks: options,
          };
          await dispatch(editTask(payload));
          navigate("/tasks");
        }
      }
       else {
        if (options[options?.length - 1]?.name == ""||options[options?.length - 1]?.days == "") {
          toast.error("Please fill out the required fields");
        } else {
          const payload = {
            taskname: taskname,
            category: category,
            description: description,
            subtasks: options,
          };
          console.log("The Form Date is ======>>>>", payload);
          dispatch(addNewTask(payload));

          settaskname("");
          setcategory("");
          setoptions([{ name: "", days: "" }]);
          navigate("/tasks");
        }
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  return (
<>
<div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Tasks</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Task</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">Add Task</h2>
    <div className="wf-radius-20 white-bg wf-shadow p-3 mb-4">
      <div className="row gy-3 mb-3">
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="text"  class="form-control" id="task-name" placeholder="Task Name"  defaultValue={taskname} onChange={(e) => settaskname(e.target.value)}/>
      <label for="task-name">Task Name</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="dropdown wf-select-dropdown mb-3">
            <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {category ? categories?.filter((ci) => ci?.parent_id==null)?.find(user => user?._id === category)?.name : "Categories"}
            </a>
            <ul class="dropdown-menu">
            {categories?.filter((ci) => ci?.parent_id==null)?.map((c,i)=>(
              <li key={i}><a onClick={(e)=>setcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
              ))}
            </ul>
      </div>
      </div>
      <div className="col-md-12">
      <div class="form-floating mb-3 wf-textarea">
    <textarea class="form-control" placeholder="description" id="description" defaultValue={description} onChange={(e)=>setdescription(e.target.value)} style={{height:'100px'}}></textarea>
    <label for="description">Description</label>
    </div>
    </div>
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Answer Options</p>
      {prevoptions?.length > 0 && prevoptions?.map((o, i) => (
      <div className="row mb-3">
        <div className="col-md-8">
        <div class="form-floating wf-input">
          <input type="text"  class="form-control" id={`Answer-Option-${i+1}`} placeholder={`Enter Option ${i+1}`} defaultValue={o.name} onChange={(e) => {
           const updatedOptions = [...prevoptions]; updatedOptions[i].name = e.target.value; setprevoptions(updatedOptions); }}/>
          <label for={`Answer-Option-${i+1}`}>Enter Subtask {i+1}</label>
        </div>
        </div>
        <div className="col-md-3">
        <div class="form-floating wf-input">
          <input type="number"  class="form-control" id={`days-Option-${i+1}`} placeholder={`Enter Option ${i+1}`} defaultValue={o.days} onChange={(e) => {
           const updatedOptions = [...prevoptions]; updatedOptions[i].days = e.target.value; setprevoptions(updatedOptions);}}/>
          <label for={`days-Option-${i+1}`}>Enter Days {i+1}</label>
        </div>
      </div>
      {i > 0 && (
        <div className="col-md-1 align-self-center trash-icon" onClick={() => {
          const updatedoptions = [...prevoptions]; updatedoptions.splice(i, 1); setprevoptions(updatedoptions); }}>
          <Trash2 className="pink-color cursor-pointer" />
        </div>
      )}
    </div>
    ))}

    {options?.length > 0 && options?.map((o, i) => (
    <div className="row mb-3">
    <div className="col-md-8">
    <div class="form-floating wf-input">
      <input type="text"  class="form-control" id={`Answer-Option-${i+1}`} placeholder={`Enter Option ${i+1}`} defaultValue={o.name} onChange={(e) => {
     const updatedOptions = [...options]; updatedOptions[i].name = e.target.value; setoptions(updatedOptions);}}/>
      <label for={`Answer-Option-${i+1}`}>{ id == "" ? `Enter SubTask ${i + 1}` : `Enter SubTask ${prevoptions?.length + i + 1}`}</label>
    </div>
  </div>
  <div className="col-md-3">
  <div class="form-floating wf-input">
      <input type="number"  class="form-control" id={`days-Option-${i+1}`} placeholder={`Enter Option ${i+1}`} defaultValue={o.days} onChange={(e) => { 
        const updatedOptions = [...options]; updatedOptions[i].days = e.target.value; setoptions(updatedOptions);}}/>
      <label for={`days-Option-${i+1}`}>Enter Days {i+1}</label>
    </div>
  </div>
    {id == "" && i > 0 ? (
      <div className="col-md-1 align-self-center trash-icon" onClick={() => { 
        const updatedoptions = [...options]; updatedoptions.splice(i, 1); setoptions(updatedoptions);}}>
        <Trash2 className="pink-color cursor-pointer" />
      </div>
    ) : id !== "" ? (
      <div
        className="col-md-1 align-self-center trash-icon"
        onClick={() => {
          const updatedoptions = [...options];
          updatedoptions.splice(i, 1);
          setoptions(updatedoptions);
        }}
      >
        <Trash2 className="pink-color cursor-pointer" />
      </div>
    ) : null}
  </div>
))}

    {options[options?.length - 1]?.name !== "" && options[options?.length - 1]?.days !== "" &&  prevoptions[prevoptions?.length - 1]?.name !== "" && options[prevoptions?.length - 1]?.days !== "" && ( <a className="font-size-14 fw-semibold purple-color" onClick={addMoreOptions}>Add More Options +</a>)}
    </div>
    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/tasks")} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
      <button onClick={addandEdittask} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>
    </div>

    {/* <main className="dashboard">
      <section className="content-dashboard">
        <div className="blur-background admin-panel">
          <div className="admin-panel-height">

  
            
     

            {prevoptions?.length > 0 &&
              prevoptions?.map((o, i) => (
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <div className="control">
                        <input
                          className="add-fields-input"
                          type="text"
                          placeholder={`Enter Option ${i + 1}`}
                          defaultValue={o.name}
                          onChange={(e) => {
                            const updatedOptions = [...prevoptions];
                            updatedOptions[i].name = e.target.value;
                            setprevoptions(updatedOptions);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-3">
                    <div className="field">
                      <div className="control">
                        <div class="file has-name">
                          <label class="file-label main">
                            <input
                              class="file-input"
                              type="file"
                              name="resume"
                              onChange={(e) => {
                                const updatedOptions = [...prevoptions];
                                updatedOptions[i].imageName = e.target.files[0];
                                setprevoptions(updatedOptions);
                              }}
                            />
                            <span class="file-cta">
                              <span class="file-icon">
                                <i class="fas fa-upload"></i>
                              </span>
                              <span class="file-label">
                                {id !== ""
                                  ? prevoptions[i]?.imageName
                                  : id == "" && prevoptions[i]?.imageName !== ""
                                  ? prevoptions[i]?.imageName?.name
                                  : "No file chosen"}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {i > 0 && (
                    <div
                      className="column trash-icon"
                      onClick={() => {
                        const updatedoptions = [...prevoptions];
                        updatedoptions.splice(i, 1);
                        setprevoptions(updatedoptions);
                      }}
                    >
                      <Trash2 />
                    </div>
                  )}
                </div>
              ))}

            {options?.length > 0 &&
              options?.map((o, i) => (
                <div className="columns">
                  <div className="column ">
                    <div className="field">
                      <div className="control">
                        <input
                          className="add-fields-input"
                          type="text"
                          placeholder={
                            id == ""
                              ? `Enter Option ${i + 1}`
                              : `Enter Option ${prevoptions?.length + i + 1}`
                          }
                          defaultValue={o.name}
                          onChange={(e) => {
                            const updatedOptions = [...options];
                            updatedOptions[i].name = e.target.value;
                            setoptions(updatedOptions);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column is-3">
        <div className="field">
                  <div className="control">
            <div class="file has-name">
            <label class="file-label main">
            <input class="file-input" type="file" name="resume" onChange={(e) => {
                const updatedOptions = [...options];
                updatedOptions[i].imageName = e.target.files[0];
                setoptions(updatedOptions);
              }}/>
            <span class="file-cta">
                <span class="file-icon">
                    <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                {id!==""&&options[i]?.imageName==""?"No file chosen":id!=="" && options[i]?.imageName!==""?options[i]?.imageName?.name:id=="" && options[i]?.imageName!==""?options[i]?.imageName?.name:"No file chosen"}
                </span>
            </span>
           
            </label>
            </div>
        </div>
                  </div>
        </div>
                  {id == "" && i > 0 ? (
                    <div
                      className="column trash-icon"
                      onClick={() => {
                        const updatedoptions = [...options];
                        updatedoptions.splice(i, 1);
                        setoptions(updatedoptions);
                      }}
                    >
                      <Trash2 />
                    </div>
                  ) : id !== "" ? (
                    <div
                      className="column trash-icon"
                      onClick={() => {
                        const updatedoptions = [...options];
                        updatedoptions.splice(i, 1);
                        setoptions(updatedoptions);
                      }}
                    >
                      <Trash2 />
                    </div>
                  ) : null}
                </div>
              ))}

            {options[options?.length - 1]?.name !== "" &&
              options[options?.length - 1]?.imageName !== "" &&
              prevoptions[prevoptions?.length - 1]?.name !== "" &&
              options[prevoptions?.length - 1]?.imageName !== "" && (
                <a
                  className="font-size-18 font-semibold-calibre black-color1"
                  onClick={addMoreOptions}
                >
                  Add More Options +
                </a>
              )}
            <div className="spacer-20"></div>
            <button
              onClick={addandEdittask}
              className="button is-block is-fullwidth"
            >
              {id ? "Edit Task" : "Add Task"} {id ? <Pencil /> : <Plus />}
            </button>
          </div>
        </div>
      </section>
    </main> */}
    </>
  );
};

export default AddTask;
