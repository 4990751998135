import React, { useEffect, useState } from "react";
import { Trash2 } from "lucide-react";
import {  useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllCategories,
  addQuestion,
  editQuestion,
} from "../../store/slices/userSlice";
import toast from 'react-hot-toast';
import Spinner from "../../components/Spinner";

const AddQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [id, setId] = useState("");
  const [categories, setcategories] = useState(null);
  const [questionCustomer, setquestionCustomer] = useState("");
  const [questionVendor, setquestionVendor] = useState("");
  const [headingText, setheadingText] = useState("");
  const [category, setcategory] = useState("");
  const [type, settype] = useState("");
  const [role, setrole] = useState([]);
  const [options, setoptions] = useState([]);
  const [prevoptions, setprevoptions] = useState([]);
  const [load, setload] = useState(false);


  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getAllCategories()).unwrap();
      setcategories(response?.data);
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Categories();
    if(location?.state?._id){
      setId(location?.state?._id?._id)
      setquestionCustomer(location?.state?._id?.questionCustomer)
      setquestionVendor(location?.state?._id?.questionVendor)
      setheadingText(location?.state?._id?.headingText)
      setcategory(location?.state?._id?.category_id?._id)
      setrole(location?.state?._id?.question_for)
      settype(location?.state?._id?.type)
      setprevoptions(location?.state?._id?.options)
    }
    else{
      setoptions([{ name: "", imageName: "" }])
    }
  }, []);

  const addMoreOptions = () => {
    setoptions([...options, { name: "", imageName: "" }]);
  };

  const addandEditQuestion = async (e) => {
    e.preventDefault();

    try {
      setload(true)
      const formData= new FormData();
      formData.append("questionCustomer", questionCustomer)
      formData.append("questionVendor", questionVendor)
      formData.append("headingText", headingText)
      formData.append("category", category)
      formData.append("type", type)
      formData.append("role", JSON.stringify(role))
      options.forEach((opt,index)=>{
        formData.append(`options`, opt.name)
        formData.append(`imageName`, opt.imageName)
      })

      const formData1= new FormData();
      formData1.append("id", id)
      formData1.append("questionCustomer", questionCustomer)
      formData1.append("questionVendor", questionVendor)
      formData1.append("headingText", headingText)
      formData1.append("category", category)
      formData1.append("type", type)
      formData1.append("role", JSON.stringify(role))
      formData1.append("prevoptions", JSON.stringify(prevoptions))
      options.forEach((opt,index)=>{
        formData1.append(`options`, opt.name)
        formData1.append(`imageName`, opt.imageName)
      })

      // prevoptions.forEach((opt,index)=>{

      //   formData1.append(`_id`, opt._id)
      //   formData1.append(`options`, opt.name)
      //   formData1.append(`imageName`, opt.imageName)
      // })

    
      if (id) {
        if(options[options?.length-1]?.imageName==""||options[options?.length-1]?.name==""){
          toast.error("Please fill out the required fields")
          setload(false)
        }
        else{
        await dispatch(editQuestion(formData1)).unwrap();        
        setload(false)  
        navigate("/questions")
      }
    } else { 
        if(options[options?.length-1]?.imageName==""||options[options?.length-1]?.name==""){
          toast.error("Please fill out the required fields")
          setload(false)
        }
        else{
          await dispatch(addQuestion(formData)).unwrap();
          setload(false)
          navigate("/questions")
            setquestionCustomer("")
            setquestionVendor("")
            setcategory("")
            settype("")
            setrole([])
            setheadingText("")
            setoptions([{ name: "", imageName: "" }])
        }
            
      }
      
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
      setload(false)
    }
  };

  const handleCheckboxChange = (selectedRole) => {
    if (role.includes(selectedRole)) {
      setrole(role.filter(role => role !== selectedRole));
    } else {
      setrole([...role, selectedRole]);
    }
  };

  return (
    <>

{load && <Spinner />}
<div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Question</a></li>
            <li class="breadcrumb-item active" aria-current="page">Add Question</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">Add Question</h2>
    <div className="wf-radius-20 white-bg wf-shadow p-3 mb-4">
      <div className="row gy-3 mb-3">
      <div className="col-md-12">
      <div class="form-floating wf-input">
      <input type="text"  class="form-control" id="Question-Title" placeholder="Question Title"  defaultValue={headingText} onChange={(e)=>setheadingText(e.target.value)}/>
      <label for="Question-Title">Question Heading</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="text"  class="form-control" id="Question-Title" placeholder="Question Title"  defaultValue={questionCustomer} onChange={(e)=>setquestionCustomer(e.target.value)}/>
      <label for="Question-Title">Question Customer Title</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="text"  class="form-control" id="Question-Title" placeholder="Question Title"  defaultValue={questionVendor} onChange={(e)=>setquestionVendor(e.target.value)}/>
      <label for="Question-Title">Question Vendor Title</label>
    </div>
      </div>
      <div className="col-md-6">
      <div class="dropdown wf-select-dropdown mb-3">
            <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {category ? categories?.filter((ci) => ci?.parent_id==null)?.find(user => user?._id === category)?.name : "Categories"}
            </a>
            <ul class="dropdown-menu">
            {categories?.filter((ci) => ci?.parent_id==null)?.map((c,i)=>(
              <li key={i}><a onClick={(e)=>setcategory(c?._id)} class="dropdown-item">{c?.name}</a></li>
              ))}
            </ul>
      </div>
      </div>
      <div className="col-md-6">
      <div class="dropdown wf-select-dropdown mb-3">
            <a class="dropdown-toggle text-capitalize" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {type ? type : "Type"}
            </a>
            <ul class="dropdown-menu">
              <li><a onClick={()=>settype("checkbox")} class="dropdown-item">Checkbox</a></li>
              <li><a onClick={()=>settype("radio")} class="dropdown-item">Radio</a></li>
            </ul>
      </div>
      </div>
      <div className="col-md-12">
      <p class="font-size-20 pink-color dm-font mb-2">Question For</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      <input class="btn-check" type="checkbox" name="question-for" id="check-customer" checked={role?.includes("customer")} onChange={() => handleCheckboxChange("customer")} value="customer" />
        <label class="btn wf-select-btn" for="check-customer">Customer</label>

        <input class="btn-check" type="checkbox" name="question-for" id="check-vendor" checked={role?.includes("vendor")} onChange={() => handleCheckboxChange("vendor")} value="vendor" />
        <label class="btn wf-select-btn" for="check-vendor">Vendor</label>
      </div>
      </div>
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Answer Options</p>
    {prevoptions?.length>0&&prevoptions?.map((o,i)=>(
    <div className="row mb-3">
        <div className="col-md-8">
        <div class="form-floating wf-input">
          <input type="text"  class="form-control" id={`Answer-Option-${i+1}`} placeholder={`Enter Option ${i+1}`} defaultValue={o.name} onChange={(e) => {
            const updatedOptions = [...prevoptions]; updatedOptions[i].name = e.target.value; setprevoptions(updatedOptions);}}/>
          <label for={`Answer-Option-${i+1}`}>Answer Option {i+1}</label>
        </div>
        </div>
        <div className="col-md-3 col-11">
        <div class="form-floating wf-input">
          <input type="file"  class="form-control" id={`Answer-Option-icon-${i+1}`} placeholder={`Enter Option ${i+1}`} onChange={(e) => {
            const updatedOptions = [...prevoptions]; updatedOptions[i].imageName = e.target.files[0]; setprevoptions(updatedOptions);}}/>
          <label for={`Answer-Option-icon-${i+1}`}>Answer Option Icon {i+1}</label>
        </div>
        </div>
        {i > 0 &&(<div className="col-1 align-self-center trash-icon" onClick={() => {
            const updatedoptions = [...prevoptions];
            updatedoptions.splice(i, 1);
            setprevoptions(updatedoptions);
          }} ><Trash2 className="pink-color cursor-pointer" /></div>)}
    </div>))}
    
    {options?.length>0&&options?.map((o,i)=>(<div className="row mb-3">
    <div className="col-md-8">
        <div class="form-floating wf-input">
          <input type="text"  class="form-control" id={`Answer-Option-${i+1}`} placeholder={id==""?`Enter Option ${i+1}`:`Enter Option ${prevoptions?.length+i+1}`} defaultValue={o.name} 
          onChange={(e) => { const updatedOptions = [...options]; updatedOptions[i].name = e.target.value; setoptions(updatedOptions);}}/>
          <label for={`Answer-Option-${i+1}`}>Answer Option {i+1}</label>
        </div>
        </div>
        <div className="col-md-3 col-11">
        <div class="form-floating wf-input">
          <input type="file"  class="form-control" id={`Answer-Option-icon-${i+1}`} placeholder={`Enter Option ${i+1}`} onChange={(e) => { 
            const updatedOptions = [...options]; updatedOptions[i].imageName = e.target.files[0]; setoptions(updatedOptions);}}/>
          <label for={`Answer-Option-icon-${i+1}`}>Answer Option Icon {i+1}</label>
        </div>
        </div>
        {id==""&& i > 0 ?(<div className="col align-self-center trash-icon" onClick={() => {
            const updatedoptions = [...options];
            updatedoptions.splice(i, 1);
            setoptions(updatedoptions);
          }} ><Trash2 className="pink-color cursor-pointer" /></div>):id!==""?(<div className="col-1 trash-icon" onClick={() => {
            const updatedoptions = [...options];
            updatedoptions.splice(i, 1);
            setoptions(updatedoptions);
          }} ><Trash2 className="pink-color cursor-pointer" /></div>):null}
    </div>))}


    {options[options?.length-1]?.name!==""&&options[options?.length-1]?.imageName!==""&&prevoptions[prevoptions?.length-1]?.name!==""&&options[prevoptions?.length-1]?.imageName!=="" &&(<a  className="font-size-14 fw-semibold purple-color" onClick={addMoreOptions}>Add More Options +</a>)}


    </div>
    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/questions")} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
      <button onClick={addandEditQuestion} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>
    </div>

    </>
  )
}

export default AddQuestion