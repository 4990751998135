import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Camera, ChevronsDown, Trash2, X } from "lucide-react";
import {
  getServices,
  editstorefrontadmin,
  getvendorquestionsadmin,
  getAllStores,
  getAllAreas,
  getServiceTypes,
  getAllCountries,
  getEthnics,
  getAttribute,
  getSubAttribute,
  getCities,
  getStoreDetails
} from "../../store/slices/userSlice";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import Spinner from "../../components/Spinner";



const AddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [cities, setcities] = useState(null);
  const [citiesFilter, setcitiesFilter] = useState(null);
  const [citiesLoad, setcitiesLoad] = useState(false);
  const [areas, setareas] = useState(null); 
  const [areasLoad, setareasLoad] = useState(false); 
  const [areasFilter, setareasFilter] = useState(null); 
  const [questions, setquestions] = useState([]);
  const [value, setvalue] = useState(null);
  const [services, setServices] = useState(null);
  const [servicetypes, setservicetypes] = useState(null);
  const [ethnics, setEthnics] = useState(null);
  const [initattributes, setinitattributes] = useState(null);
  const [initsubattributes, setinitsubattributes] = useState(null);
  const [prevCountry, setPrevCountry] = useState(null);
  const [pricing, setpricing] = useState("");
  const [store_name, setstore_name] = useState("");
  const [slug, setslug] = useState("");
  const [description, setdescription] = useState("");
  const [overview, setoverview] = useState("");
  const [weddings_hosted, setweddings_hosted] = useState("");
  const [team_members, setteam_members] = useState("");
  const [min_price, setmin_price] = useState("");
  const [max_price, setmax_price] = useState("");
  const [prefered_services, setPreferedServices] = useState([]);
  const [prefered_typeofservices, setprefered_typeofservices] = useState([]);
  const [prefered_sub_services, setprefered_sub_services] = useState([]);
  const [attributes, setattributes] = useState([]);
  const [sub_attributes, setsub_attributes] = useState([]);
  const [insurance, setinsurance] = useState("");
  const [business_attributes, setbusiness_attributes] = useState([]);
  const [business_ethnics, setbusiness_ethnics] = useState([]);
  const [contact_person, setcontact_person] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [website, setwebsite] = useState("");
  const [pobox_no, setpobox_no] = useState("");
  const [address, setaddress] = useState("");
  const [country, setcountry] = useState("66596fcc9079984b6b40ad6e");
  const [mycities, setmycities] = useState("");
  const [city, setcity] = useState("");
  const [myareas, setmyareas] = useState("");
  const [area, setarea] = useState("");
  const [location, setlocation] = useState("");
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);
  const [business_startdate, setbusiness_startdate] = useState("");
  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [pinterest, setpinterest] = useState("")
  const [video, setvideo] = useState([])
  const [newVideoLink, setnewVideoLink] = useState("")
  const [allvendors, setallvendors] = useState([])
  const [suggested_vendors, setsuggested_vendors] = useState([])
  const [searchValue5, setSearchValue5] = useState('');
  const [showDropdown5, setShowDropdown5] = useState(false);
  const [faq, setFAQ] = useState([{ question: "", answer: "" }]);
  const [percentage,setpercentage] = useState("none")
  const [lessthan50,setlessthan50]= useState("")
  const [from50to100,setfrom50to100]= useState("")
  const [from100to150,setfrom100to150]= useState("")
  const [from150to250,setfrom150to250]= useState("")
  const [from250to350,setfrom250to350]= useState("")
  const [from350to500,setfrom350to500]= useState("")
  const [from500to750,setfrom500to750]= useState("")
  const [from750to1000,setfrom750to1000]= useState("")
  const [morethan1000,setmorethan1000]= useState("")
  const [store_deal,setstore_deal]= useState([])
  const [service_name,setservice_name]= useState("")
  const [price,setprice]= useState("")
  const [dp,setdp]=useState("")
  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [geocountry,setgeocountry] = useState("");
  const [geocity,setgeocity] = useState("");
  const [geoarea,setgeoarea] = useState("");
  
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

  function handleDateChange(e) {
    const inputDate = e.target.value;
    const currentDate = getCurrentDate();

    if (inputDate > currentDate) {
        e.target.value = currentDate; 
    }
  }
 

  if (value) {
    geocodeByPlaceId(value?.value?.place_id)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
        const area = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name
        || addressComponents.find(component => component.types.includes('route'))?.long_name
        || addressComponents.find(component => component.types.includes('street_address'))?.long_name;

        setgeocountry(country || "");
        setgeocity(city || "");
        setgeoarea(area || "");
  
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        console.log('lat', lat);
        setlat(lat);
        setlong(lng);
        setlocation(value?.label);
      })
      .catch((error) => console.error(error));
  }

  const removeVendorById = (_id) => {
    setsuggested_vendors((prevVendors) =>
      prevVendors.filter((vendor) => vendor._id !== _id)
    );
  };


  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.error(rejectedValueOrSerializedError);
    }
  };

  const Ethnics = async () => {
    try {
      setEthnics(null);
      const response = await dispatch(getEthnics()).unwrap();
      setEthnics(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const urlParts = window.location.pathname.split('/');
  const vendorId = urlParts[urlParts.length - 1];

  const Details = async () => {
    try {
        setload(true);
        const data = await dispatch(getStoreDetails({id: vendorId})).unwrap();
        setstore_name(data?.data?.store_name);
        setslug(data?.data?.slug);
        setdescription(data?.data?.description);
        setoverview(data?.data?.overview);
        setweddings_hosted(data?.data?.weddings_hosted);
        setteam_members(data?.data?.team_members);
        setlocation(data?.data?.location?.location);
        setlat(data?.data?.location?.coordinates[1]);
        setlong(data?.data?.location?.coordinates[0]);
        setpobox_no(data?.data?.pobox_no);
        // setcountry(data?.data?.country?.[0]?._id);
        setmycities(data?.data?.cities?.[0]?._id);
        setcity(data?.data?.cities?.[0]?._id);
        setSearchValue1(data?.data?.cities?.[0]?.name);
        setmyareas(data?.data?.areas?.[0]?._id);
        setarea(data?.data?.areas?.[0]?._id);
        setSearchValue2(data?.data?.areas?.[0]?.name);
        setaddress(data?.data?.address);
        setmin_price(data?.data?.min_price);
        setmax_price(data?.data?.max_price);
        setbusiness_ethnics(data?.data?.business_ethnics?.map((m)=>m?._id));
        setcontact_person(data?.data?.contact_person);
        setphone(data?.data?.phone);
        setFAQ(data?.data?.faqs?.data?.length>0?data?.data?.faqs?.data:[{ question: "", answer: "" }]);
        setbusiness_attributes(data?.data?.business_attributes);
        setinsurance(data?.data?.insurance);
        setPreferedServices(data?.data?.prefered_services?.map((m)=>m?._id));
        setpricing(data?.data?.prefered_services[0]?.pricing_type);
        setprefered_typeofservices(data?.data?.prefered_typeofservices?.map((m)=>m?._id));     
        setprefered_sub_services(data?.data?.sub_services);
        setattributes(data?.data?.attributes?.map((m)=>m?._id));
        setvideo(data?.data?.video);
        setsub_attributes(data?.data?.sub_attributes?.map((m)=>m?._id));
        setmycities([data?.data?.cities?.[0]?._id]);    
        setbusiness_startdate(data?.data?.business_startdate);     
        setwhatsapp( data?.data?.whatsapp);
        setemail(data?.data?.email);
        setmobile(data?.data?.mobile);
        setpinterest(data?.data?.pinterest);
        setyoutube( data?.data?.youtube);
        setwebsite( data?.data?.website);
        setfacebook( data?.data?.facebook);
        setpercentage( data?.data?.discount);
        setinstagram( data?.data?.instagram);
        settwitter( data?.data?.twitter);
        setvideo( data?.data?.video);
        setlinkedin( data?.data?.linkedin);
        setdp( data?.data?.image);
        setprev_images(data?.data?.imageName?.length>0?data?.data?.imageName:[])
        setload(false)
      } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (vendorId !== "add-vendor") {
      Details();
    }
  }, [vendorId]);


  const ServiceTypes = async (id) => {
    try {
      setservicetypes(null);
      const response = await dispatch(getServiceTypes(id)).unwrap();
      setservicetypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getAttributes = async (id) => {
    try {
      setinitattributes(null);
      const response = await dispatch(getAttribute({id: id})).unwrap();
      setinitattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getSubAttributes = async (id) => {
    try {
      setinitsubattributes(null);
      const response = await dispatch(getSubAttribute({id: id})).unwrap();
      setinitsubattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getQuestions = async (id) => {
    try {
      setquestions(null);
      const response = await dispatch(getvendorquestionsadmin({id:id})).unwrap();
      setquestions(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handlePricingChange = (e,i) => {
    if(i===0){
      setlessthan50(e.target.value)
    }
    if(i===1){
      setfrom50to100(e.target.value)
    }
    if(i===2){
      setfrom100to150(e.target.value)
    }
    if(i===3){
      setfrom150to250(e.target.value)
    }
    if(i===4){
      setfrom250to350(e.target.value)
    }
    if(i===5){
      setfrom350to500(e.target.value)
    }
    if(i===6){
      setfrom500to750(e.target.value)
    }
    if(i===7){
      setfrom750to1000(e.target.value)
    }
    if(i===8){
      setmorethan1000(e.target.value)
    }
  };

  const getCity = async () => {
    try {
      setcities(null);
      const response = await dispatch(getCities()).unwrap();
      setcities(response?.data)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getSuggestedVendors = async () => {
    try {
      setallvendors(null);
      const response = await dispatch(getAllStores()).unwrap();
      if (vendorId !== "add-vendor") {
        const filteredData = response?.data.filter((vendor) => vendor._id.toString() !== vendorId.toString());
        setallvendors(filteredData);
      } else{
        setallvendors(response?.data);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Areas = async () => {
    try {
      setareas(null);
      const response = await dispatch(getAllAreas()).unwrap();
      setareas(response?.data);
      setareasLoad(true)
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  const handleImageUpload = (event) => {
    const selectedImages = event.target.files;
    const imagesArray = [];
    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  useEffect(() => {
    Services();
    Ethnics();
    Areas();
    getSuggestedVendors();
    getCity();
  }, []);

  useEffect(() => {
    if(prefered_services){
      getAttributes(prefered_services[0])
      getSubAttributes(prefered_services[0])
      getQuestions(prefered_services)
      ServiceTypes(prefered_services);
    }
  }, [prefered_services]);
  
  const handleInputChange5 = (e) => {
    const value = e.target.value;
    setSearchValue5(value);
    if (value.length > 0) {
      setShowDropdown5(true);
    } else {
      setShowDropdown5(false);
    }
  };

  const handleOptionClick5 = (option) => {
    setSearchValue5("");
    setsuggested_vendors([...suggested_vendors,{_id: option?._id, store_name: option?.store_name}])
    setShowDropdown5(false);
  };


  const handleEthnicChange = (eventid) => {
    console.log('eventid', eventid)
    if (!business_ethnics?.includes(eventid)) {
      setbusiness_ethnics([...business_ethnics, eventid]); 
    } else {
      const updatedEventIds = business_ethnics?.filter((id) => id !== eventid);
      setbusiness_ethnics(updatedEventIds);
    }
  };

  const handleSkillChange4 = (answer, question) => {
    const updatedSubServices = [...prefered_sub_services];
    const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === question);
  
    if (existingObjectIndex !== -1) {
      const existingObject = updatedSubServices[existingObjectIndex];
      const answerIndex = existingObject.item.indexOf(answer);
  
      if (answerIndex !== -1) {
        existingObject.item.splice(answerIndex, 1);
        if (existingObject.item.length === 0) {
          updatedSubServices.splice(existingObjectIndex, 1);
        }
      } else {
        existingObject.item.push(answer);
      }
    } else {
      updatedSubServices.push({ _id: question, item: [answer] });
    }
    setprefered_sub_services(updatedSubServices);
  };

  const handleSkillChange2 = async (eventid) => {
    if (!attributes?.includes(eventid)) {
      setattributes([...attributes, eventid]); 
    } else {
      const updatedEventIds = attributes?.filter((id) => id !== eventid);
      setattributes(updatedEventIds);
    }
  };
 
  const handleSkillChange3 = async (eventid) => {
    if (!sub_attributes?.includes(eventid)) {
      setsub_attributes([...sub_attributes, eventid]); 
    } else {
      const updatedEventIds = sub_attributes?.filter((id) => id !== eventid);
      setsub_attributes(updatedEventIds);
    }
  };


  const saveBranch = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("event_id", vendorId !== "add-vendor" ? vendorId : "");
      data.append("store_name", store_name);
      data.append("slug", slug);
      data.append("description", description);
      data.append("overview", overview);
      data.append("weddings_hosted", weddings_hosted);
      data.append("team_members", team_members);
      data.append("min_price", min_price);
      data.append("max_price", max_price);
      data.append("prefered_services", JSON.stringify(prefered_services));
      data.append("prefered_typeofservices", JSON.stringify(prefered_typeofservices));
      data.append("sub_services", JSON.stringify(prefered_sub_services));
      data.append("attributes", JSON.stringify(attributes));
      data.append("sub_attributes", JSON.stringify(sub_attributes));
      data.append("location", location);
      data.append("lat", lat);
      data.append("long", long);
      data.append("insurance", insurance);
      data.append("business_attributes", JSON.stringify(business_attributes));
      data.append("business_ethnics", JSON.stringify(business_ethnics));
      data.append("contact_person", contact_person);
      data.append("phone", phone);
      data.append("email", email);
      data.append("mobile", mobile);
      data.append("whatsapp", whatsapp);
      data.append("website", website);
      data.append("pobox_no", pobox_no);
      data.append("address", address);
      data.append("country", JSON.stringify(country));
      data.append("cities", JSON.stringify(mycities));
      data.append("areas", JSON.stringify(myareas));
      data.append("business_startdate", business_startdate);
      data.append("facebook", facebook);
      data.append("twitter", twitter);
      data.append("linkedin", linkedin);  
      data.append("instagram", instagram);
      data.append("youtube", youtube);
      data.append("pinterest", pinterest);
      data.append("video", JSON.stringify(video));
      data.append("faqs", faq&&faq[0]?.question!==""&&faq[0]?.answer!==""?JSON.stringify(faq):JSON.stringify([]));
      data.append("percentage", percentage);
      if(prefered_services[0]?.pricing_type=="pricing")
      {data.append("lessthan50", lessthan50);
      data.append("from50to100", from50to100);
      data.append("from100to150", from100to150);
      data.append("from150to250", from150to250);
      data.append("from250to350", from250to350);
      data.append("from350to500", from350to500);
      data.append("from500to750", from500to750);
      data.append("from750to1000", from750to1000);
      data.append("morethan1000", morethan1000);}
      if(prefered_services[0]?.pricing_type=="deal"&&store_deal!=="undefined"&&store_deal!==undefined)
      {data.append("store_deal", JSON.stringify(store_deal))}
      data.append("pricing", "");
      data.append("image", dp);
      data.append("suggested_vendors", JSON.stringify(suggested_vendors));
      data.append("prev_picture", JSON.stringify(prev_images));
      images.forEach((item) => {
      data.append("imageName", item);
      });
      const response = await dispatch(editstorefrontadmin(data)).unwrap();
      setload(false);
      navigate("/vendors")
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    } 
  };

  const addMoreFAQ = () => {
    setFAQ([...faq, { question: "", answer: "" }]);
  };

  const addMoreDeal = () => {
    setstore_deal([...store_deal, { service_name: service_name, price: price }]);
    setservice_name("")
    setprice("")
  };


  const numberofWeddings = [
    {
      id: 1,
      text: '<10',
    },
    {
      id: 2,
      text: '10-25',
    },
    {
      id: 3,
      text: '25-30',
    },
    {
      id: 4,
      text: '50+',
    },
  ];
  const numberofTeam = [
    {
      id: 5,
      text: '1-2',
    },
    {
      id: 6,
      text: '3-10',
    },
    {
      id: 7,
      text: '11-25',
    },
    {
      id: 8,
      text: '25+',
    },
  ];

  const BusinessAttributes = [
    {
      id: 9,
      text: 'Emarati-Owned',
    },
    {
      id: 10,
      text: 'Arab-Owned',
    },
    {
      id: 11,
      text: 'Woman-Owned',
    },
    {
      id: 12,
      text: 'Asian-Owned',
    },
  ];

  const pricingArray=["Less than 50", "From 50 to 100", "From 100 to 150", "From 150 to 250", "From 250 to 350", "From 350 to 500", "From 500 to 750", "From 750 to 1000", "More than 1000"]


  useEffect(() => {
    const selectedArea = areas?.find(area => area.name === geoarea);
    if (selectedArea) {
      setmyareas(selectedArea._id);
      setarea(selectedArea._id);
      setSearchValue2(selectedArea.name);
    }
    const findCity = cities?.find(user => user.name === geocity)
    if(findCity){
      setmycities(findCity?._id)
      setcity(findCity?._id)
      setSearchValue1(findCity?.name)
    }
  }, [geocountry, geocity, geoarea, cities, areas]); 


  const handleCountryChange = (countryId) => {
    setcountry(countryId);
  }; 

  const [showDropdown1, setShowDropdown1] = useState(false);
  const [searchValue1, setSearchValue1] = useState('');
  const [highlightedIndex1, setHighlightedIndex1] = useState(-1);

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [searchValue2, setSearchValue2] = useState('');
  const [highlightedIndex2, setHighlightedIndex2] = useState(-1);


  const handleInputChange1 = (e) => {
    const value = e.target.value;
    setSearchValue1(value);
    if (value.length > 0) {
      setShowDropdown1(true);
    } else {
      setShowDropdown1(false);
    }
  };

  const handleOptionClick1 = (data) => {
    setSearchValue1(data?.name);
    setmycities(data?._id);
    setcity(data?._id);
    setShowDropdown1(false);
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setSearchValue2(value);
    if (value.length > 0) {
      setShowDropdown2(true);
    } else {
      setShowDropdown2(false);
    }
  };
  const handleOptionClick2 = (data) => {
    setSearchValue2(data?.name);
    setarea(data?._id);
    setmyareas(data?._id);
    setShowDropdown2(false);
  };

  const filteredCities = cities?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue1?.toLowerCase())
  );

  const filteredAreas = areasFilter?.filter((data) =>
    data?.name?.toLowerCase().includes(searchValue2?.toLowerCase())
  );

  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex1((prevIndex) =>
        prevIndex < filteredCities.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex1((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex1 !== -1) {
        handleOptionClick1(filteredCities[highlightedIndex1]);
      }
    }
  };

  const handleKeyDown2 = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex2((prevIndex) =>
        prevIndex < filteredAreas.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex2((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    } else if (e.key === 'Enter') {
      if (highlightedIndex2 !== -1) {
        handleOptionClick1(filteredAreas[highlightedIndex2]);
      }
    }
  };
  
  
  useEffect(() => {
    if(city){
      const filterAreas = areas?.filter(item => item?.city_id?._id === city)
      console.log(filterAreas, "filterAreas");
      setareasFilter(filterAreas)
    }
  }, [city, areasLoad]);
  

   
  return (
    <>
    {load && <Spinner />}
    <div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Vendors</a></li>
            <li class="breadcrumb-item active" aria-current="page">{vendorId !== "add-vendor" ? "Edit" : "Add"} Vendor</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">{vendorId !== "add-vendor" ? "Edit" : "Add"} Vendor</h2>
    <div className="wf-radius-20 white-bg wf-shadow p-3 mb-4">
    <p class="font-size-26 black-color dm-font mb-2">Business Details</p>
      <div className="row gy-3 mb-3">
      <div className="col-md-5">
      <div class="form-floating wf-input">
      <input type="text" onChange={(e) => setstore_name(e.target.value)} value={store_name} class="form-control" id="business-name" placeholder="Business Name" required/>
      <label for="business-name">Business Name</label>
      </div>
      </div>
      {vendorId !== "add-vendor" &&(<div className="col-md-4">
      <div class="form-floating wf-input">
      <input onChange={(e) => setslug(e.target.value)} value={slug} type="text" class="form-control" id="business-slug" placeholder="Business Slug" required/>
      <label for="business-slug">Business Slug</label>
      </div>
      </div>)}
      <div className="col-md-3">
      <div class="form-floating wf-input">
      <input type="date" onChange={(e) => setbusiness_startdate(e.target.value)} onBlur={handleDateChange} max={getCurrentDate()} value={business_startdate} class="form-control" id="Operating-Since" placeholder="12/june/2023"  required/>
      <label for="Operating-Since">Operating Since</label>
      </div>
      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Type of Service</p>
        <div className="d-flex flex-wrap gap-2 mb-3">
        {services?.map((s) => (
          <>
          <input class="btn-check" name="types-of-service" type="radio" id={s?._id} value={s._id} checked={prefered_services?.includes(s._id)} onChange={() => {setPreferedServices([s._id]); setpricing(s.pricing_type)}} />
          <label class="btn wf-select-btn" for={s?._id}>{s?.name}</label>
          </>
        ))}
        </div>

        {servicetypes?.length > 0 ? (<>
          <p class="font-size-20 pink-color dm-font mb-2">Types of Sub Service</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {servicetypes? servicetypes[0]?.subcategories?.map((s) => (
            <>
            <input class="btn-check" type="radio" name="sub-cat" id={s._id} value={s._id} checked={prefered_typeofservices?.includes(s._id)?true:false} onChange={() => setprefered_typeofservices([s._id])} />
            <label class="btn wf-select-btn" for={s._id}>
              {s.name}
            </label>
            </>
          )): null}
          </div>
          </>
          ): null}

          {questions?.length > 0 &&questions?.map((q)=>(
          <> 
          <p class="font-size-20 pink-color dm-font mb-2">{q.question}</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {q?.options?.map((s) => (
            <>
            <input class="btn-check" type="checkbox" name="questions" checked={prefered_sub_services?.some((obj) => obj._id == q._id && obj.item?.includes(s._id))} onChange={() => handleSkillChange4(s?._id, q?._id)} />
            <label class="btn wf-select-btn" >{s.name}</label>
            </>
          ))}
          </div>
          </>
            ))}
              
          {initattributes && initattributes[0]?.subcategories?.length > 0 && (
              <>
                <p class="font-size-20 pink-color dm-font mb-2">Which Attributes best suits you</p>
                <div className="d-flex flex-wrap gap-2 mb-3">
                {initattributes[0]?.subcategories?.map((s) => (
                    <>
                      <input className="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={attributes?.includes(s._id)} onChange={() => handleSkillChange2(s?._id)}/>
                      <label className="btn wf-select-btn" htmlFor={s._id}>{s.name}</label>
                    </>
                ))}
                </div>
              </>
            )}

            {initsubattributes && initsubattributes[0]?.subcategories?.length > 0 && (
            <>
              <p class="font-size-20 pink-color dm-font mb-2">What are the extra features (sub attributes) you provide</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
              {initsubattributes[0]?.subcategories?.map((s) => (
                  <>
                    <input className="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={sub_attributes?.includes(s._id)}  onChange={() => handleSkillChange3( s?._id )}/>
                    <label className="btn wf-select-btn" htmlFor={s._id}>{s.name}</label>
                  </>
              ))}
              </div>
            </>
          )}

      </div>
      <div className="col-md-6">
      <div class="form-floating mb-3 wf-textarea">
        <textarea class="form-control" placeholder="Brief Overview" id="Brief-Overview" onChange={(e) => setoverview(e.target.value)} value={overview} required style={{height:'150px'}}></textarea>
        <label for="Brief-Overview">Brief Overview</label>
      </div>
      <div class="form-floating mb-3 wf-textarea">
        <textarea class="form-control" placeholder="Detailed Description  (Inc Product & Services)" id="Detailed-Description" onChange={(e) => setdescription(e.target.value)} value={description}
         style={{height:'400px'}}></textarea>
        <label for="Detailed-Description">Detailed Description  (Inc Product & Services)</label>
      </div>
      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Number of Wedding Hosted</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {numberofWeddings?.map((s) => (
        <>
        <input checked={weddings_hosted==s.text?true:false} class="btn-check" type="radio" name="wedding-hosted" id={s.text} onChange={() => setweddings_hosted(s.text)} value={s.id} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Number of Team Members</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {numberofTeam?.map((s) => (
        <>
        <input checked={team_members==s.text?true:false} class="btn-check" type="radio" name="team_members" id={s.text} onChange={() => setteam_members(s.text)} value={s.id} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>
      <p class="font-size-20 pink-color dm-font mb-2">Business Holds Public Insurance</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
        <input checked={insurance=="yes"?true:false} onChange={(e) => setinsurance(e.target.value)} class="btn-check" type="radio" name="hold-insurance" id="yes" value="yes" />
        <label class="btn wf-select-btn" for="yes">Yes</label>

        <input checked={insurance=="no"?true:false} onChange={(e) => setinsurance(e.target.value)} class="btn-check" type="radio" name="hold-insurance" id="no" value="no" />
        <label class="btn wf-select-btn" for="no">No</label>
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Business Attributes</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {BusinessAttributes?.map((s) => (
        <>
        <input checked={business_attributes==s.text?true:false} onChange={(e) => setbusiness_attributes([e.target.value])} class="btn-check" type="radio" name="team-members" id={s.text} value={s.text} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>


      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Business Services Price Range</p>
      <div className="row mb-3">
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="number" onChange={(e) => setmin_price(e.target.value)} placeholder="1,000" value={min_price && min_price} class="form-control" id="Min-Rates" required/>
      <label for="Min-Rates">Min Rates</label>
      </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="number" onChange={(e) => setmax_price(e.target.value)} placeholder="10,000" value={max_price && max_price} class="form-control" id="Max-Rates" required/>
      <label for="Max-Rates">Max Rates</label>
      </div>
      </div>
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Would You Consider Your Business Specialist for Any of the Following Wedding Types?</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
        {ethnics?.map((s) => (
          <>
          <input checked={business_ethnics?.includes(s._id)?true:false} onChange={() => handleEthnicChange(s._id)}  class="btn-check" type="checkbox" name="team-members" id={s._id} value={s._id} />
          <label class="btn wf-select-btn" for={s._id}>{s.name}</label>
          </>
        ))}
        </div>
      </div>
      </div>

    <p class="font-size-26 black-color dm-font mb-2">Contact Details</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Contact-person" placeholder="Contact person" onChange={(e) => setcontact_person(e.target.value)} value={contact_person} />
      <label for="Contact-person">Contact person</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="tel" class="form-control" id="Phone" placeholder="Phone" onChange={(e) => setphone(e.target.value)} value={phone} />
      <label for="Phone">Phone</label>
    </div>
    </div>  
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="email" class="form-control" id="Email" placeholder="Email" onChange={(e) => setemail(e.target.value)} value={email} />
      <label for="Email">Email</label>
    </div>
    </div>  
    <div className="col-md-6 d-flex gap-2 align-items-center">
    <div class="form-floating wf-input w-100">
      <input type="tel" class="form-control" id="Mobile" placeholder="Mobile" value={mobile} onChange={(e) => setmobile(e.target.value)}/>
      <label for="Mobile">Mobile</label>
    </div>
    <div class="form-check form-check-inline">
      <input checked={whatsapp?true:false} onChange={(e) => setwhatsapp(e.target.checked ? mobile : "")} value={whatsapp} class="form-check-input" type="checkbox" name="hold-insurance" id="whatsapp" />
      <label class="form-check-label" for="whatsapp">
      Whatsapp
      </label>
    </div>
    </div>  
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="url" class="form-control" id="Website" placeholder="Website" value={website} onChange={(e) => setwebsite(e.target.value)} />
      <label for="Website">Website</label>
    </div>
    </div> 
    </div>  
    <p class="font-size-26 black-color dm-font mb-2">Location Details</p>
    <div className="row mb-3">
    <div className="col-md-6">
    <p class="font-size-20 pink-color dm-font mb-2">Location</p>
    <div className="wf-shadow rounded mb-3">
    <GooglePlacesAutocomplete apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY}  selectProps={{ value, onChange: setvalue, placeholder: location && vendorId !== "add-vendor" ? location : "Enter your location", }} />
    </div>       
    <div class="form-floating mb-3 wf-textarea">
      <textarea class="form-control" placeholder="Address" id="Address" onChange={(e) => setaddress(e.target.value)} value={address}  
      style={{height:'150px'}}></textarea>
      <label for="Address">Address</label>
    </div>
    </div>
    <div className="col-md-6">
      <div className="">
        <div class="form-floating wf-input mt-3">
          <input type="text" class="form-control" onKeyDown={handleKeyDown1} maxLength={120} onChange={(e) => handleInputChange1(e)} value={searchValue1} id="city" placeholder="city" autoComplete="off" />
          <label for="city">City</label>
        </div>
        {showDropdown1 && (
          <div className="dropdown wf-select-dropdown">
            <ul className="dropdown-menu show" style={{ width: '100%' }}>
              {cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.map((data, index) => (
                <li key={index} onClick={() => handleOptionClick1(data)} className={highlightedIndex1 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
              ))}
              {cities?.length > 0 && cities?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue1?.toLowerCase()))?.length === 0 && (
                <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
              )}
            </ul>
          </div>
        )}
      </div>
    {city && (
      <div className="mt-3">
        <div className="">
        <div class="form-floating wf-input">
          <input type="text" class="form-control" onKeyDown={handleKeyDown2} maxLength={120} onChange={(e) => handleInputChange2(e)} value={searchValue2} id="area" placeholder="area" autoComplete="off" />
          <label for="area">Area</label>
        </div>
        </div>
        {showDropdown2 && (
          <div className="dropdown wf-select-dropdown">
            <ul className="dropdown-menu show" style={{ width: '100%' }}>
              {areasFilter?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.map((data, index) => (
                <li key={index} onClick={() => handleOptionClick2(data)} className={highlightedIndex2 === index ? 'highlighted' : ''}><a class="dropdown-item" >{data?.name}</a></li>
              ))}
              {areasFilter?.length > 0 && areasFilter?.filter((data) => data?.name?.toLowerCase()?.includes(searchValue2?.toLowerCase()))?.length === 0 && (
                <li><a class="dropdown-item" ><i className="hasNoResults">No matching results</i></a></li>
              )}
            </ul>
          </div>
        )}
      </div>
    )}
    <div class="form-floating wf-input mt-3">
      <input type="text" class="form-control" onChange={(e) => setpobox_no(e.target.value)} value={pobox_no} maxLength={9} id="postcode" placeholder="PostCode" />
      <label for="postcode">Postcode</label>
    </div>
  </div>
    </div>
    <p class="font-size-26 black-color dm-font mb-2">Social Network</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Facebook" placeholder="Facebook" value={facebook} onChange={(e) => setfacebook(e.target.value)}/>
      <label for="Facebook">Facebook</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="X" placeholder="X" value={twitter} onChange={(e) => settwitter(e.target.value)}/>
      <label for="X">X</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" value={linkedin && linkedin} onChange={(e) => setlinkedin(e.target.value)}/>
      <label for="LinkedIn">LinkedIn</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Instagram" placeholder="Instagram" value={instagram} onChange={(e) => setinstagram(e.target.value)}/>
      <label for="Instagram">Instagram</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Youtube" placeholder="Youtube" value={youtube} onChange={(e) => setyoutube(e.target.value)}/>
      <label for="Youtube">Youtube</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Pinterest" placeholder="Pinterest" value={pinterest} onChange={(e) => setpinterest(e.target.value)}/>
      <label for="Pinterest">Pinterest</label>
    </div>
    </div>
    </div>

    <p class="font-size-26 black-color dm-font mb-2">FAQs</p>
    {faq?.length > 0 &&faq.map((f, i) => (
      <div className="mb-3" key={i + 1}>
        <div className="d-flex justify-content-between">
        <p class="font-size-20 pink-color dm-font mb-2">FAQ {i + 1}</p>
        {i > 0 && (<span className="cursor-pointer pink-color" onClick={() => { const updatedFAQs = [...faq]; updatedFAQs.splice(i, 1); setFAQ(updatedFAQs); }} >
          <Trash2 width="18" />
          </span>)}
        </div>
        <div class="form-floating wf-input mb-3" key={`question-${i}`}>
        <input type="text" class="form-control" id="FAQ-Question" placeholder="FAQ Question" value={f.question} onChange={(e) => { const updatedFAQs = [...faq]; updatedFAQs[i].question = e.target.value; setFAQ(updatedFAQs); }}/>
        <label for="FAQ-Question">FAQ Question</label>
        </div>
        <div class="form-floating mb-3 wf-textarea" key={`answer-${i}`}>
        <textarea class="form-control" placeholder="FAQ Answer" id="FAQ-Answer"  value={f.answer} onChange={(e) => {
          const updatedFAQs = [...faq]; updatedFAQs[i].answer = e.target.value; setFAQ(updatedFAQs);}} style={{height:'150px'}}></textarea>
        <label for="FAQ-Answer">FAQ Answer</label>
      </div>

      {faq[i]?.question!==""&&faq[i]?.answer!==""&&(<a className="font-size-16 purple-color mb-3 d-block" onClick={addMoreFAQ}>Add more FAQs + </a>)}
      </div>
    ))}

        {pricing=="pricing"&&(<div>
        <label class="form-label">Pricing by Guest Count</label>
        <div className="row mb-24 gy-3">
        {pricingArray?.map((p,i)=>(<div className="col-md-4">
        <div class="form-group"> <label class="form-label">{p}</label> 
        <input onChange={(e)=>handlePricingChange(e,i)} class="form-control" type="number"/> 
        </div>
        </div>))}
        </div>
        </div>)}

        {pricing=="deal"&&(
                <div className="mb-3">
                <p class="font-size-20 pink-color dm-font mb-2">Pricing by Service</p>
                <div className="row">
                <div className="col-md-7">
                <div class="form-floating wf-input">
                <input type="text" class="form-control" id="Service-Name" placeholder="Service Name" onChange={(e)=>setservice_name(e.target.value)} value={service_name}/>
                <label for="Service-Name">Service Name</label>
                </div>
                </div>
                <div className="col-md-3">
                <div class="form-floating wf-input">
                <input type="number" class="form-control" id="Price" placeholder="Price" onChange={(e)=>setprice(e.target.value)} value={price}/>
                <label for="Price">Price</label>
                </div>
                </div>
                <div className="col-md-1 align-self-center">
                <a onClick={addMoreDeal} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Add</a>
                </div>
                </div>
                {store_deal?.map((data, i) => (
                    <div className="row pricing-service-list" key={i}>
                        <div className="col-md-11">
                            <div className="wf-shadow white-bg rounded p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                    <input
                                        className="form-control font-size-14 pink-color mb-0 text-uppercase"
                                        value={data.service_name}
                                        onChange={(e) => {
                                            const updatedServiceName = [...store_deal];
                                            updatedServiceName[i].service_name = e.target.value;
                                            setstore_deal(updatedServiceName);
                                        }}
                                    />
                                    <input
                                    style={{width:"30%"}}
                                        className="form-control font-size-14 purple-color mb-0 text-uppercase"
                                        value={`AED ${data.price}`}
                                        onChange={(e) => {
                                            const updatedPrice = [...store_deal];
                                            updatedPrice[i].price = e.target.value;
                                            setstore_deal(updatedPrice);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-flex gap-2 font-size-20 align-items-center align-self-start">
                            <i
                                className="bi bi-trash purple-color cursor-pointer"
                                onClick={() => {
                                    const updatedServiceName = [...store_deal];
                                    updatedServiceName.splice(i, 1);
                                    setstore_deal(updatedServiceName);
                                }}
                            ></i>
                        </div>
                    </div>
                ))}
        </div>)}


        <p class="font-size-20 pink-color dm-font mb-2">Add Special Discounts (Exclusive discount for Weddified Couples)</p>
        <div className="d-flex flex-wrap gap-2 mb-3">

        <input className="btn-check" type="radio" name="discountOptions" id="noneDiscount" value="0" checked={percentage == 'none'||percentage == 0?true:false} onChange={() =>  setpercentage("none") } />
        <label className="btn wf-select-btn" htmlFor="noneDiscount">None</label>


        <input  className="btn-check" type="radio" name="discountOptions" id="5PercentDiscount" value="5" checked={percentage == '5'?true:false} onChange={() => setpercentage(5)} />
        <label className="btn wf-select-btn" htmlFor="5PercentDiscount">5% Discount</label>
 

        <input className="btn-check" type="radio" name="discountOptions" id="10PercentDiscount" value="10" checked={percentage == '10'?true:false} onChange={() => setpercentage(10)} />
        <label className="btn wf-select-btn" htmlFor="10PercentDiscount">10% Discount</label>


        <input className="btn-check" type="radio" name="discountOptions" id="10PercentDiscount" value="15" checked={percentage == '15'?true:false} onChange={() => setpercentage(15)} />
        <label className="btn wf-select-btn" htmlFor="10PercentDiscount">15% Discount</label>

        <input className="btn-check" type="radio" name="discountOptions" id="20PercentDiscount" value="20" checked={percentage == '20'?true:false} onChange={() => setpercentage(20)} />
        <label className="btn wf-select-btn" htmlFor="20PercentDiscount">20% Discount</label>
    

        <input className="btn-check" type="radio" name="discountOptions" id="otherDiscount" value="other" checked={!percentage == "0"&&!percentage === "5"&&!percentage === "10"&&!percentage === "15"&&!percentage === "20"?true:false} onChange={(e) => setpercentage(e.target.value)} />
        <label className="btn wf-select-btn" htmlFor="otherDiscount">Other</label>
 

      {percentage !== 0  && percentage !== 5 && percentage !== 10 && percentage !== 15 && percentage !== 20 ? (
      <input type="number" placeholder="Enter Other Discount %" value={percentage} className="form-control wf-shadow border-0 py-2 font-size-14 w-auto px-3" onChange={(e) => setpercentage(e.target.value)} required /> ) : (
          <></>
        )}
      
        </div>


        <p class="font-size-26 black-color dm-font mb-2">Photos & Videos</p>

        <div className="upload__img-wrap mb-3">
           {prev_images?.length>0&&prev_images.map((image, index) => (
                <div className="upload__img-box" key={index}>
                  <div className="img-bg">
                    <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                    <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                    <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                      <div class="form-check form-check-inline">
                    <input checked={dp==image?true:false} onChange={()=>setdp(image)} class="form-check-input" type="radio" name="dp" id="dp" />
                    <label class="form-check-label" for="dp">Featured Image</label>
                    </div>
                      </ul>
                    </div>
                </div>
                  </div>
                </div>
              ))}

            {images?.length>0&&images.map((image, index) => (
                <div className="upload__img-box" key={index}>
                  <div className="img-bg">
                    <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                    <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                    <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                      <div class="form-check form-check-inline">
                    <input checked={dp==image?true:false} onChange={()=>setdp(image)} class="form-check-input" type="radio" name="dp" id="dp" />
                    <label class="form-check-label" for="dp">Featured Image</label>
                    </div>
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              ))} 
              <input type="file" class="btn-check" id="btn-check" multiple data-max_length="20" onChange={handleImageUpload}  accept=".png, .jpg, .jpeg, .webp"/>
              <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
            </div>


        <p class="font-size-20 pink-color dm-font mb-2">Youtube / Vimeo / Dailymotion Video URL</p>
        <div className="d-flex mb-3">
        <div class="form-floating wf-input w-100">
          <input type="text" value={newVideoLink} onChange={(e) => setnewVideoLink(e.target.value)} class="form-control" id="search-value" placeholder="Video URL" />
          <label for="search-value">Video URL</label>
        </div>
        <a onClick={()=>{setvideo([...video,newVideoLink]); setnewVideoLink("")}} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 d-flex align-items-center">Add</a>
        </div>

        <div className="row mb-3">
        {video?.map((videoLink, index) => (
          <div className="col-md-4" key={index}>
            <X className="cursor-pointer" onClick={() => {
            const updatedVideo = [...video];
            updatedVideo.splice(index, 1);
            setvideo(updatedVideo);
        }}/>
            <iframe title={`Video Player ${index + 1}`} width="100%" height="200" src={`https://www.youtube.com/embed/${videoLink?.split('v=')[1]}`} frameBorder="0" allowFullScreen></iframe>
          </div>
        ))}
        </div>
        <p class="font-size-20 pink-color dm-font mb-2">Preferred Suppliers</p>
      <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Suggested-Vendors" placeholder="Suggested Vendors (Search Vendors)" value={searchValue5} onChange={handleInputChange5}/>
      <label for="Suggested-Vendors">Suggested Vendors (Search Vendors)</label>
      </div>
      {showDropdown5 && (
            <div className="dropdown">
            <div className="dropdown-menu show" style={{ width: '100%' }}>
              <div className="list-autocomplete">
                {allvendors
                  ?.filter((option) =>
                    option.store_name.toLowerCase().includes(searchValue5.toLowerCase())
                  )
                  .map((option, index) => (
                    <button
                      key={index}
                      type="button"
                      className="dropdown-item"
                      onClick={() => handleOptionClick5(option)}
                    >
                      {option.store_name}
                    </button>
                  ))}
                {allvendors?.length > 0 &&
                  allvendors
                    ?.filter((option) =>
                      option.store_name.toLowerCase().includes(searchValue5.toLowerCase())
                    ).length === 0 && (
                  <i className="hasNoResults">No matching results</i>
                )}
              </div>
            </div>
            </div>
          )}
      {suggested_vendors?.map((s)=>(<p className="fw-semibold mb-3"><i onClick={() => removeVendorById(s?._id)} className="bi bi-x-circle cursor-pointer"></i> {s?.store_name}</p>))}

    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=>navigate("/vendors")} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
      <button onClick={saveBranch} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">{vendorId !== "add-vendor" ? "Update Vendor" : "Submit"}</button>
    </div>
    </div>

   
    </>
  )
}

export default AddVendor