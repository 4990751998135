
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile } from "../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

const RequireAuth = ({ user, allowedRoles, children }) => {
  const navigate=useNavigate()

  const profile = useSelector(getProfile);

  console.log('getProfile', profile)
  console.log('allowedRoles', allowedRoles)

 if(profile&&allowedRoles?.includes(profile.role)){
  console.log('yesssssssssssshh')
  return children
}
else{
   console.log('yesssssssssssshh1')
  return navigate("/")
 }
};

export default RequireAuth;