import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getAllAdmins,
    deleteAdmin
} from "../../store/slices/userSlice";
import { Trash2 } from "lucide-react";
import moment from "moment"
import { X } from "lucide-react";
import { ArrowRight } from "lucide-react";
import { Pencil } from "lucide-react";
import { Plus } from "lucide-react";
import Modal from "react-modal";
const customStyles = {
  content: {
    display:"block"
  },
};
Modal.setAppElement("#root");


const Admins = () => {
  const [adminCount, setadminCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [id, setId] = useState("");
  const [admins, setadmins] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();


  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("")
  }

  const adminDelete = async (id) => {
    try {
      await dispatch(deleteAdmin(id)).unwrap();
      setIsOpen(false);
      Admins();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  
  const Admins = async () => {
    try {
      setadmins(null);
      const response = await dispatch(getAllAdmins()).unwrap();
      setadmins(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Admins();
  }, []);

  return (
    <>

<Modal
closeTimeoutMS={500}
isOpen={modalIsOpen}
onRequestClose={closeModal}
style={customStyles}
contentLabel="Change Password"
className={`modal custom-modal fade ${modalIsOpen&&"show"} `}
>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        {modalType == "userDetail" ? (
    <>
      </>
      ): modalType == "delete" ? ( 
        <>
        <div class="modal-content">
                <div class="modal-body">
                    <div class="form-header">
                        <h3>Delete Admin User</h3>
                        <p>Are you sure you want to delete this Admin User?</p>
                    </div>
                    <div class="modal-btn delete-action">
                        <div class="row">
                        <div class="col-6 mb-0">
                                <a onClick={closeModal} class="btn btn-primary cancel-btn">Cancel</a>
                            </div>
                            <div class="col-6 mb-0">
                                <a onClick={() => adminDelete(id)} class="btn btn-primary continue-btn">Delete</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        ) : (
        <></>
      )}
        </div>
 
</Modal>


<div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb wf-breadcrumb">
        <li class="breadcrumb-item"><a>Menu</a></li>
        <li class="breadcrumb-item"><a>Admins</a></li>
        <li class="breadcrumb-item active" aria-current="page">All Admins</li>
      </ol>
    </nav>
    <div className="row gy-3 mb-4 align-items-center mb-2">
      <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
        <h2 className="font-size-28 pink-color dm-font mb-0">Admins</h2>
      </div>
      <div className="col-md-6 order-md-2 order-3">

      </div>
      <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
        <button class="btn wf-btn wf-purple-btn px-3" onClick={()=>navigate("/add-admin")}>Add Admin</button>
      </div>
    </div>
    <div className="wf-radius-20 white-bg wf-shadow p-3">
      <p className="font-size-20 pink-color">Admins : {admins?.length}</p>
      <div class="table-responsive">
        <table class="table wf-table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="col">S.No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Vendor Store</th>
              <th scope="col">Blogs</th>
              <th scope="col">Users</th>
              <th scope="col">System Config</th>
              <th style={{ width: '100px' }} scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {admins?.map((item, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>{item?.stores_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.stores_access?.length-1?", ":""}</>))}</td>
                <td>{item?.blogs_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.blogs_access?.length-1?", ":""}</>))}</td>
                <td>{item?.users_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.users_access?.length-1?", ":""}</>))}</td>
                <td>{item?.systemconfig_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.systemconfig_access?.length-1?", ":""}</>))}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <div onClick={()=>navigate("/add-admin", { state: { _id: item },})} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
                    <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>
                  </div>
                </td>
              </tr>))}
          </tbody>
        </table>
      </div>
    </div>
  </div>

{/* <div class="main">
        <div class="main-content client project">
            <div class="row mb-20">
                <div class="col-12 col-xl-7 col-md-8 col-sm-12">
                    <div class="box card-box">
                        <div class="icon-box bg-color-10">
                            <div class="icon bg-icon-10">
                                <i class='bx bxs-briefcase'></i>
                            </div>
                            <div class="content color-10">
                                <h5 class="title-box fs-17 font-w400 mb-1">Admins Users</h5>
                                <div class="excellencetheme-counter fs-18 font-wb">
                                    <span class="number" data-from="0" data-to="1225" data-speed="2500" data-inviewport="yes">{adminCount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-5 col-md-4 col-sm-12 ">
                    <div class="box h-100 d-flex align-items-center">
                        <a onClick={()=>navigate("/add-admin")} class="cursor-pointer create d-flex bg-primary justify-content-center">
                            <div class="icon color-white pt-4 pr-8">
                                <i class='bx bx-plus-circle'></i>
                            </div>
                            <div class="content">
                                <h5 class="color-white">ADD ADMIN</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-8 col-xl-12">
                    <div class="box pd-0">
                    <div class="box-body pt-20 pl-15 pr-15 pb-20 table-responsive activity mt-10">
                        <table class="table table-vcenter text-nowrap table-bordered dataTable no-footer mw-100" id="task-profile" role="grid">
                            <thead>
                                <tr class="top">
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "50px"}}>S.No</th>
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "120px"}}>Name</th>
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "84.8281px"}}>Email</th>
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "84.8281px"}}>Vendor Store Access</th>
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "87.9844px"}}>Blogs Access</th>
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "87.9844px"}}>Users Access</th>
                                    <th class="border-bottom-0 sorting fs-14 font-w500" tabindex="0" aria-controls="task-profile" rowspan="1" colspan="1" style={{"width": "87.9844px"}}>System Config Access</th>
                                    <th class="border-bottom-0 sorting_disabled fs-14 font-w500" rowspan="1" colspan="1" style={{"width": "145.391px"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {admins?.map((item,i)=>(<tr key={i} class="odd">
                                  <td>{i+1}</td>
                                  <td>{item?.name}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.stores_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.stores_access?.length-1?", ":""}</>))}</td>
                                  <td>{item?.blogs_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.blogs_access?.length-1?", ":""}</>))}</td>
                                  <td>{item?.users_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.users_access?.length-1?", ":""}</>))}</td>
                                  <td>{item?.systemconfig_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.systemconfig_access?.length-1?", ":""}</>))}</td>

                                    <td>
                                        <div class="d-flex gap-3">
                                        <a class="btn-link cursor-pointer" onClick={() => viewModal(item?._id, "delete")} ><i class="bx bx-trash"></i> Delete</a>
                                                <a class="btn-link cursor-pointer" onClick={()=>navigate("/add-admin", { state: { _id: item },})} ><i class="bx bx-edit mr-5"></i>Edit</a>
                                        </div>
                                    </td>
                                </tr>))}
                   

                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                
            </div>


        </div>
    </div> */}


    {/* <main className="dashboard">
   
    <section className="content-dashboard">
    <div className="blur-background admin-panel">
    <div className="admin-panel-height">

    
    <div class="columns">
  <div class="column is-10">
  <h1 className="font-size-26 font-semibold-calibre black-color1">Sub Admins</h1>
  </div>
  <div class="column is-2">
  <button onClick={()=>navigate("/add-admin")} className="button is-block is-fullwidth">Add Sub-Admin <Plus /></button>
  </div>
</div>

            <div className="table-responsive" id="tableready">
              <table
                id="tableData"
             
                className="table table-bordered"
                style={{ width: "100%", textAlign: "left" }}
              >
                <thead>
                <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Vendor Store Access</th>
                      <th>Blogs Access</th>
                      <th>Users Access</th>
                      <th>System Config Access</th>
                      <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {admins?.map((item,i)=>(<tr key={i}>
                        <td>{i+1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.stores_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.stores_access?.length-1?", ":""}</>))}</td>
                        <td>{item?.blogs_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.blogs_access?.length-1?", ":""}</>))}</td>
                        <td>{item?.users_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.users_access?.length-1?", ":""}</>))}</td>
                        <td>{item?.systemconfig_access?.map((c,i)=>(<>{c.charAt(0).toUpperCase() + c.slice(1)}{i<item?.systemconfig_access?.length-1?", ":""}</>))}</td>              
                        <td className="action-icons">
                          <div
                            className="edit-bg-icon"
                            onClick={()=>navigate("/add-admin", {
                              state: {
                                _id: item
                              },
                            })}
                          >
                            <Pencil />
                          </div>
                          <div
                            className="trash-bg-icon"
                            onClick={() =>
                              viewModal(item?._id, "delete")
                            }
                          >
                            <Trash2 />
                          </div>
                        </td>
                      </tr>))}
                </tbody>
              </table>
            </div>
          </div>
          </div>

    </section>
    </main> */}
    </>
  )
}

export default Admins