import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  getAllCategories,
  addCategory,
  editCategory,
  deleteCategory
} from "../../store/slices/userSlice";
import Modal from "react-modal";
import { Pencil, Search, ThumbsUp, Trash2, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
const customStyles = {
  content: {
    display: "block",
    paddingRight: "0"
  },
};
Modal.setAppElement("#root");

const Categories = () => {
  const navigate = useNavigate();
  const profile = useSelector(getProfile)

  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [categories, setcategories] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  

  const [name, setname] = useState("");
  const [pricing_type, setpricing_type] = useState("");
  const [parent_id, setparent_id] = useState("");
  const [cover_image, setcover_image] = useState("");
  const [icon_image, seticon_image] = useState("");
  const [feature, setfeature] = useState("");
  const [toggle, settoggle] = useState(false);
  const [uniqueCreators, setUniqueCreators] = useState([]);
  const [creators, setCreators] = useState({});
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    console.log("feature", feature);
    console.log("type", type);

    if (type == "userDetail" && feature == "add") {
      setId();
      setname();
      setparent_id();
      setpricing_type();
      setcover_image();
      seticon_image();
      setfeature("add")
    }
    if (type == "userDetail" && feature == "edit") {

      setfeature("edit")
      setUserDetail(item);
      setId(item?._id);
      setname(item?.name);
      setpricing_type(item?.pricing_type);
      setparent_id(item?.parent_id?._id);
      setcover_image(item?.cover_image);
      seticon_image(item?.icon_image);
    }
    if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setUserDetail()
    setId("")
  }

  const categoryDelete = async (id) => {
    try {
      await dispatch(deleteCategory(id)).unwrap();
      setIsOpen(false);
      Categories();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Approve = async (id) => {
    try {
      await dispatch(editCategory({ id: id, approved: 1 })).unwrap();
      Categories();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Categories = async () => {
    try {
      setload(true);
      setcategories(null);
      setFilteredData(null);
      const response = await dispatch(getAllCategories()).unwrap();
      setcategories(response?.data);
      setFilteredData(response?.data);
      setload(false);

      const uniqueCreatorsSet = new Set();
      const creatorsData = [];

      response?.data.forEach((category) => {
        uniqueCreatorsSet.add(category.user_id?._id);
        creatorsData.push(category.user_id);
      });

      const uniqueCreatorsArray = Array.from(uniqueCreatorsSet);
      setUniqueCreators(uniqueCreatorsArray);

      const creatorsMap = {};
      creatorsData.forEach((creator) => {
        creatorsMap[creator._id] = creator;
      });
      setCreators(creatorsMap);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
      Categories();
  }, []);

  const addandEditCategory = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("pricing_type", pricing_type);
      data.append("cover_image", cover_image);
      data.append("icon_image", icon_image);
      data.append("parent_id", parent_id);
      if (id) {
        await dispatch(editCategory(data)).unwrap();
        setIsOpen(false);
        Categories();
        setId("")
      } else {
        await dispatch(addCategory(data)).unwrap();
        setIsOpen(false);
        Categories();
        setname("")
        setparent_id("")
        setpricing_type("")
        setcover_image("")
        seticon_image("")
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [parentFilter, setparentFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = categories?.filter(item => {
      console.log(item); // Log the item to the console for inspection

      const userName = item?.parent_id == null ? "" : item?.parent_id?.name.toLowerCase();
      const creatorName = item?.user_id?.name

      console.log(creatorName, "creatorName");

      return (
        (parentFilter === '' || userName.includes(parentFilter.toLowerCase())) &&
        (selectedUsers?.length === 0 || selectedUsers?.includes(creatorName))
      );
    });

    setFilteredData(filteredResult);
    settoggle(false);
  };

  const handleStatusChange = (status) => {
    if (selectedUsers.includes(status)) {
      setselectedUsers(prevStatuses => prevStatuses.filter(s => s !== status));
    } else {
      setselectedUsers(prevStatuses => [...prevStatuses, status]);
    }
  };

  const resetFilter = () => {
    setparentFilter('');
    setselectedUsers([]);
    setFilteredData(categories)
    settoggle(false);
  };


  useEffect(() => {
    setparent_id(userDetail?.parent_id?._id)
    setpricing_type(userDetail?.pricing_type)
}, [userDetail])

  return (
    <>
      {profile?.systemconfig_access?.includes("write") && (
        <Modal
          closeTimeoutMS={500}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Change Password"
          className={`modal fade show wf-modal ${modalType == "delete" && 'wf-modal-30'}`}
        >
          <div class="modal-dialog modal-dialog-centered wf-modal-dialog">
            {modalType == "userDetail" && feature == "add" ? (
              <>
              <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Category / Sub-Category</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating wf-input">
                      <input type="text" onChange={(e) => setname(e.target.value)} class="form-control" id="att-name" placeholder="Category / Sub-Category Name" />
                      <label for="att-name">Category / Sub-Category Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown wf-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {parent_id ? categories.find(user => user?._id === parent_id)?.name : "Parent Category (For Sub-Categories)"}
                      </a>
                      <ul class="dropdown-menu">
                      {categories?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setparent_id(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="dropdown wf-select-dropdown mb-3">
                      <a class="dropdown-toggle text-capitalize" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {pricing_type ? pricing_type : "Package Type"}
                      </a>
                      <ul class="dropdown-menu">
                      
                        <li><a onClick={(e)=>setpricing_type('')} class="dropdown-item">None</a></li>
                        <li><a onClick={(e)=>setpricing_type('pricing')} class="dropdown-item">Pricing</a></li>
                        <li><a onClick={(e)=>setpricing_type('deal')} class="dropdown-item">Deal</a></li>
                      </ul>
                  </div>
                <div class="form-floating wf-input mb-3">
                      <input type="file" onChange={(e) => setcover_image(e.target.files[0])} class="form-control" id="att-icon" placeholder="Category / Sub-Category Banner" />
                      <label for="att-icon">Category / Sub-Category Banner</label>
                  </div>
                  <div class="form-floating wf-input">
                      <input type="file"onChange={(e) => seticon_image(e.target.files[0])} class="form-control" id="att-icon" placeholder="Category / Sub-Category Icon" />
                      <label for="att-icon">Category / Sub-Category Icon</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditCategory} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Save</button>
          </div>
    </div>
              </>) : modalType == "userDetail" && feature == "edit" ? (
                <>
                <div class="modal-content">
        <div class="modal-header border-0">
            <h1 class="font-size-22 dm-font purple-color">Add Category / Sub-Category</h1>
            <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="row mb-3">
                    <div class="col-sm-6">
                    <div class="form-floating wf-input">
                      <input type="text" disabled={profile?.systemconfig_access?.includes("write") ? false : true} onChange={(e) => setname(e.target.value)} defaultValue={userDetail?.name} class="form-control" id="att-name" placeholder="Category / Sub-Category Name" />
                      <label for="att-name">Category / Sub-Category Name</label>
                    </div>
                        
                    </div>
                    <div class="col-sm-6">
                    <div class="dropdown wf-select-dropdown">
                      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {parent_id ? categories.find(user => user?._id === parent_id)?.name : "Parent Category (For Sub-Categories)"}
                      </a>
                      <ul class="dropdown-menu">
                      {categories?.map((c,i)=>(
                        <li key={i}><a onClick={(e)=>setparent_id(c?._id)} class="dropdown-item">{c?.name}</a></li>
                        ))}
                      </ul>
                    </div>
                    </div>
                </div>
                <div class="dropdown wf-select-dropdown mb-3">
                      <a class="dropdown-toggle text-capitalize" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {pricing_type ? pricing_type : "Package Type"}
                      </a>
                      <ul class="dropdown-menu">
                      
                        <li><a onClick={(e)=>setpricing_type('')} class="dropdown-item">None</a></li>
                        <li><a onClick={(e)=>setpricing_type('pricing')} class="dropdown-item">Pricing</a></li>
                        <li><a onClick={(e)=>setpricing_type('deal')} class="dropdown-item">Deal</a></li>
                      </ul>
                  </div>
                <div class="form-floating wf-input mb-3">
                      <input type="file" disabled={profile?.systemconfig_access?.includes("write") ? false : true} onChange={(e) => setcover_image(e.target.files[0])} class="form-control" id="att-icon" placeholder="Category / Sub-Category Banner" />
                      <label for="att-icon">Category / Sub-Category Banner</label>
                  </div>
                  <div class="form-floating wf-input">
                      <input type="file" disabled={profile?.systemconfig_access?.includes("write") ? false : true} onChange={(e) => seticon_image(e.target.files[0])} class="form-control" id="att-icon" placeholder="Category / Sub-Category Icon" />
                      <label for="att-icon">Category / Sub-Category Icon</label>
                  </div>
            </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button onClick={addandEditCategory} class="">Save</button>
          {profile?.systemconfig_access?.includes("write") && (<button onClick={addandEditCategory} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Update</button>)}
          </div>
    </div>
                </>
              ) : modalType == "delete" ? (
                <>
                <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                  <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Delete Category/Sub-category</h1>
                  <button type="button" onClick={()=> closeModal("create")} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body mt-0">
                    <p class="font-size-14 color-black mb-0">Are you sure you want to delete this category?</p>
                  </div>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button onClick={closeModal} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
                    <button onClick={() => categoryDelete(id)} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2">Delete</button>
                  </div>
                </div>
                </>
              ) : (
              <></>
            )}
          </div>

        </Modal>)}


      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>System Config</a></li>
            <li class="breadcrumb-item active" aria-current="page">Categories</li>
          </ol>
        </nav>
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <h2 className="font-size-28 pink-color dm-font mb-0">Categories</h2>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <div class="dropdown wf-search-dropdown">
              <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
                <span>Filter and Search</span>
                <span>
                  <Search />
                  <X />
                </span>
              </a>

              <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
                <div class="row mb-3 sp-input-filter">
                  <label for="inputname" class="col-sm-2 col-form-label">Category</label>
                  <div class="col-sm-10">
                    <input type="text" onChange={e => setparentFilter(e.target.value)} value={parentFilter} placeholder="Filter by Parent Category" class="form-control" id="inputname" />
                  </div>
                </div>
                <div class="row mb-3 sp-input-filter">
                  <label for="inputname" class="col-sm-2 col-form-label">Created By</label>
                  <div class="col-sm-10 d-flex gap-2 flex-wrap">
                    {uniqueCreators.map((creatorId, i) => (
                      <>
                        <input type="checkbox" class="btn-check" checked={selectedUsers.includes(creators[creatorId]?.name)} onChange={() => handleStatusChange(creators[creatorId]?.name)} id={`created-by-${i + 1}`} value={creators[creatorId]?.name} />
                        <label class="btn wf-btn wf-purple-btn" for={`created-by-${i + 1}`}>{creators[creatorId]?.name}</label>
                      </>
                    ))}

                  </div>
                </div>
                <div className="d-flex gap-2 justify-content-end">
                  <button class="btn wf-btn wf-purple-btn px-3" onClick={applyFilter}>Search</button>
                  <button class="btn wf-btn wf-purple-btn px-3" onClick={resetFilter}>Reset</button>
                </div>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <button class="btn wf-btn wf-purple-btn px-3" onClick={() => viewModal(null, "userDetail", "add")}>Add Category</button>
          </div>
        </div>
        <div className="wf-radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">Categories / Sub-Categories : {categories?.length}</p>


          <div class="table-responsive">
            <table class="table wf-table table-borderless table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Category / Sub-Category Name</th>
                  <th scope="col">Parent Category</th>
                  <th scope="col">Icon</th>
                  <th scope="col">Cover Image</th>
                  <th scope="col">Created By</th>
                  <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.name ? item?.name : ""}</td>
                    <td>{item?.parent_id == null ? "" : item?.parent_id?.name}</td>
                    <td><img src={`${process.env.REACT_APP_IMGURL}${item?.imageName}`} width={50} alt="" /></td>
                    <td><img src={`${process.env.REACT_APP_IMGURL}${item?.cover_image1}`} width={100} alt="" /></td>
                    <td>{item?.user_id?.name}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        {profile?.role == "admin" && item?.approved == 2 && (<div onClick={() => Approve(item?._id)} className={`wf-action-icons l-blue-bg`}><ThumbsUp width="18" /></div>)}
                        <div onClick={() => viewModal(item, "userDetail", "edit")} className={`wf-action-icons l-blue-bg`}><Pencil width="18" /></div>
                        {profile?.systemconfig_access?.includes("delete") && (<div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons l-blue-bg`}><Trash2 width="18" /></div>)}
                      </div>
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Categories
