import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Camera, ChevronsDown } from "lucide-react";
import { getServices, editstorefrontadmin, getvendorquestionsadmin, getAllAreas, getServiceTypes, getAllCountries, getEthnics, getAttribute, getSubAttribute, getCities, addArea } from "../../store/slices/userSlice";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng, } from "react-google-places-autocomplete";
import Spinner from "../../components/Spinner";
import toast from "react-hot-toast";

const QuickAddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [countries, setcountries] = useState([]);
  const [cities, setcities] = useState([]);
  const [areas, setareas] = useState([]); 
  const [questions, setquestions] = useState([]);
  const [value, setvalue] = useState(null);
  const [services, setServices] = useState(null);
  const [servicetypes, setservicetypes] = useState(null);
  const [ethnics, setEthnics] = useState(null);
  const [initattributes, setinitattributes] = useState(null);
  const [initsubattributes, setinitsubattributes] = useState(null);
  const [prevCountry, setPrevCountry] = useState(null);
  const [pricing, setpricing] = useState("");
  const [store_name, setstore_name] = useState("");
  const [description, setdescription] = useState("");
  const [overview, setoverview] = useState("");
  const [weddings_hosted, setweddings_hosted] = useState("25-30");
  const [team_members, setteam_members] = useState("25+");
  const [min_price, setmin_price] = useState(5000);
  const [max_price, setmax_price] = useState(500000);
  const [prefered_services, setPreferedServices] = useState([]);
  const [prefered_typeofservices, setprefered_typeofservices] = useState([]);
  const [prefered_sub_services, setprefered_sub_services] = useState([]);
  const [attributes, setattributes] = useState([]);
  const [sub_attributes, setsub_attributes] = useState([]);
  const [business_attributes, setbusiness_attributes] = useState(["Asian-Owned"]);
  const [business_ethnics, setbusiness_ethnics] = useState(["6503e733d22b43772664db8f"]);
  const [contact_person, setcontact_person] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [website, setwebsite] = useState("");
  const [pobox_no, setpobox_no] = useState("");
  const [address, setaddress] = useState("");
  // const [country, setcountry] = useState([]);
  const [country, setcountry] = useState("66596fcc9079984b6b40ad6e");
  const [mycities, setmycities] = useState([]);
  const [myareas, setmyareas] = useState([]);
  const [location, setlocation] = useState("");
  const [lat, setlat] = useState(0);
  const [long, setlong] = useState(0);
  const [business_startdate, setbusiness_startdate] = useState("");
  const [facebook, setfacebook] = useState("");
  const [twitter, settwitter] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [instagram, setinstagram] = useState("");
  const [youtube, setyoutube] = useState("");
  const [pinterest, setpinterest] = useState("")
  const [video, setvideo] = useState([])
  const [dp, setdp] = useState("")
  const [images, setImages] = useState([]);
  const [prev_images, setprev_images] = useState([]);
  const [geocountry,setgeocountry] = useState("");
  const [geocity,setgeocity] = useState("");
  const [geoarea,setgeoarea] = useState("");
  
  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function handleDateChange(e) {
    const inputDate = e.target.value;
    const currentDate = getCurrentDate();
    if (inputDate > currentDate) {
        e.target.value = currentDate; 
    }
  }

  if (value) {
    geocodeByPlaceId(value?.value?.place_id)
      .then((results) => {
        const addressComponents = results[0]?.address_components || [];
        const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
        const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
        const area = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name
        || addressComponents.find(component => component.types.includes('route'))?.long_name
        || addressComponents.find(component => component.types.includes('street_address'))?.long_name;

        setgeocountry(country || "");
        setgeocity(city || "");
        setgeoarea(area || "");
  
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        console.log('lat', lat);
        setlat(lat);
        setlong(lng);
        setlocation(value?.label);
      })
      .catch((error) => console.error(error));
  }

  const Services = async () => {
    try {
      setServices(null);
      const response = await dispatch(getServices()).unwrap();
      setServices(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Ethnics = async () => {
    try {
      setEthnics(null);
      const response = await dispatch(getEthnics()).unwrap();
      setEthnics(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const ServiceTypes = async (id) => {
    try {
      setservicetypes(null);
      const response = await dispatch(getServiceTypes(id)).unwrap();
      setservicetypes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getAttributes = async (id) => {
    try {
      setinitattributes(null);
      const response = await dispatch(getAttribute({id: id})).unwrap();
      setinitattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getSubAttributes = async (id) => {
    try {
      setinitsubattributes(null);
      const response = await dispatch(getSubAttribute({id: id})).unwrap();
      setinitsubattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getQuestions = async (id) => {
    try {
      setquestions(null);
      const response = await dispatch(getvendorquestionsadmin({id:id})).unwrap();
      setquestions(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleCountryChange = (countryId) => {
    if (prevCountry !== countryId) {
          setPrevCountry(countryId); 
          setcountry(countryId);
          setmycities([]);
        }
  }; 

  const getCity = async () => {
    try {
      setcities(null)
      const response = await dispatch(getCities()).unwrap();
      setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Areas = async () => {
    try {
      setareas(null);
      const response = await dispatch(getAllAreas()).unwrap();
      setareas(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleImageUpload = (event) => {
    const selectedImages = event.target.files;
    const imagesArray = [];
    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    setImages(images.concat(imagesArray));
  };

  const handleImageRemovePrev = (e, index) => {
    e.preventDefault();
    const updatedImages = [...prev_images];
    updatedImages.splice(index, 1);
    setprev_images(updatedImages);
  };

  const handleImageRemove = (e, index) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  useEffect(() => {
    Services();
    // Countries();
    getCity();
    Ethnics();
    Areas();
  }, []);

  useEffect(() => {
      getAttributes(prefered_services[0])
      getSubAttributes(prefered_services[0])
      getQuestions(prefered_services)
      ServiceTypes(prefered_services);
  }, [prefered_services]);


  const handleEthnicChange = (eventid) => {
    if (!business_ethnics?.includes(eventid)) {
      setbusiness_ethnics([...business_ethnics, eventid]); 
    } else {
      const updatedEventIds = business_ethnics?.filter((id) => id !== eventid);
      setbusiness_ethnics(updatedEventIds);
    }
  };

  const handleSkillChange4 = (answer, question) => {
    const updatedSubServices = [...prefered_sub_services];
    const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === question);
    if (existingObjectIndex !== -1) {
      const existingObject = updatedSubServices[existingObjectIndex];
      const answerIndex = existingObject.item.indexOf(answer);
      if (answerIndex !== -1) {
        existingObject.item.splice(answerIndex, 1);
        if (existingObject.item.length === 0) {
          updatedSubServices.splice(existingObjectIndex, 1);
        }
      } else {
        existingObject.item.push(answer);
      }
    } else {
      updatedSubServices.push({ _id: question, item: [answer] });
    }
    setprefered_sub_services(updatedSubServices);
  };
 

  const handleSkillChange2 = async (eventid) => {
    if (!attributes?.includes(eventid)) {
      setattributes([...attributes, eventid]); 
    } else {
      const updatedEventIds = attributes?.filter((id) => id !== eventid);
      setattributes(updatedEventIds);
    }
  };
 
  const handleSkillChange3 = async (eventid) => {
    if (!sub_attributes?.includes(eventid)) {
      setsub_attributes([...sub_attributes, eventid]); 
    } else {
      const updatedEventIds = sub_attributes?.filter((id) => id !== eventid);
      setsub_attributes(updatedEventIds);
    }
  };


  const saveBranch = async (e) => {
    e.preventDefault();
    try {
      if(!store_name){
        toast.error("Business Name Missing")
        return;
      }
      if(!business_startdate){
        toast.error("Operating Since Date Missing")
        return;
      }
      if(!overview){
        toast.error("Breif Overview Missing")
        return;
      }
      if(prefered_services.length === 0){
        toast.error("Type of Service Missing")
        return;
      }
      if(!mobile){
        toast.error("Mobile Number Missing")
        return;
      }
      setload(true);
      const data = new FormData();
      data.append("event_id","");
      data.append("store_name", store_name);
      data.append("description", description);
      data.append("overview", overview);
      data.append("weddings_hosted", weddings_hosted);
      data.append("team_members", team_members);
      data.append("min_price", min_price);
      data.append("max_price", max_price);
      data.append("prefered_services", JSON.stringify(prefered_services));
      data.append("prefered_typeofservices", JSON.stringify(prefered_typeofservices));
      data.append("sub_services", JSON.stringify(prefered_sub_services));
      data.append("attributes", JSON.stringify(attributes));
      data.append("sub_attributes", JSON.stringify(sub_attributes));
      data.append("location", location);
      data.append("lat", lat);
      data.append("long", long);
      data.append("insurance", "");
      data.append("business_attributes", JSON.stringify(business_attributes));
      data.append("business_ethnics", JSON.stringify(business_ethnics));
      data.append("contact_person", contact_person);
      data.append("phone", phone);
      data.append("email", email);
      data.append("mobile", mobile);
      data.append("whatsapp", whatsapp);
      data.append("website", website);
      data.append("pobox_no", pobox_no);
      data.append("address", address);
      data.append("country", JSON.stringify(country));
      data.append("cities", JSON.stringify(mycities));
      data.append("areas", JSON.stringify(myareas));
      data.append("business_startdate", business_startdate);
      data.append("facebook", facebook);
      data.append("twitter", twitter);
      data.append("linkedin", linkedin);  
      data.append("instagram", instagram);
      data.append("youtube", youtube);
      data.append("pinterest", pinterest);
      data.append("video", JSON.stringify(video));
      data.append("faqs", JSON.stringify([]));
      data.append("percentage", "");
      data.append("pricing", pricing);
      data.append("image", dp);
      data.append("suggested_vendors", JSON.stringify([]));
      data.append("prev_picture", JSON.stringify(prev_images));
      images.forEach((item) => {
      data.append("imageName", item);
      });
      console.log('Form Data:', Object.fromEntries(data.entries()));
      await dispatch(editstorefrontadmin(data)).unwrap();
      setload(false);
      navigate('/vendors')
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };


  const numberofWeddings = [
    {
      id: 1,
      text: '<10',
    },
    {
      id: 2,
      text: '10-25',
    },
    {
      id: 3,
      text: '25-30',
    },
    {
      id: 4,
      text: '50+',
    },
  ];
  const numberofTeam = [
    {
      id: 5,
      text: '1-2',
    },
    {
      id: 6,
      text: '3-10',
    },
    {
      id: 7,
      text: '11-25',
    },
    {
      id: 8,
      text: '25+',
    },
  ];

  const BusinessAttributes = [
    {
      id: 9,
      text: 'Emarati-Owned',
    },
    {
      id: 10,
      text: 'Arab-Owned',
    },
    {
      id: 11,
      text: 'Woman-Owned',
    },
    {
      id: 12,
      text: 'Asian-Owned',
    },
  ];

  const [searchValue2, setSearchValue2] = useState('');
  const [showDropdown2, setShowDropdown2] = useState(false);

  const handleInputChange2 = (e) => {
    setSearchValue2(e.target.value);
    setShowDropdown2(true);
  };

  const handleInputBlur2 = () => {
    if (!searchValue2) {
      setShowDropdown2(false);
    }
  };

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const response = await dispatch(getAllAreas()).unwrap();
        setareas(response?.data);
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    };
    fetchAreas();
  }, []);

  const handleOptionClick2 = async (optionId) => {
    try {
      if (optionId) {
        setmyareas(optionId);
        const selectedArea = areas.find((user) => user?._id === optionId);
        if (selectedArea) {
          setSearchValue2(selectedArea.name);
        }
        setShowDropdown2(false);
        console.log('Selected Area ID:', optionId);
      } else {
        const response = await dispatch(addArea({ name: searchValue2, city_id: mycities })).unwrap();
        console.log('Add Area Response:', response);
        await Areas();
        if (response?.status === 1 && response?.data) {
          await Areas();
          setmyareas(response?.data?._id);
          setSearchValue2(response?.data?.name);
          console.log('Added Area ID:', response?.data?._id);
        } else {
          console.error('Failed to add area:', response?.message);
        }
        setShowDropdown2(false);
      }
    } catch (rejectedValueOrSerializedError) {
      console.error(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    setcontact_person(store_name);
    setphone(mobile);
    setaddress(location);
  }, [store_name, mobile, location]);



  useEffect(() => {
    const selectedArea = areas?.find(area => area.name === geoarea);
    if (selectedArea) {
      setmyareas(selectedArea._id);
    } else {
      setSearchValue2(geoarea);
    }
    setcountry(countries?.find(user => user.name === geocountry)?._id)
    setmycities(cities?.find(user => user.name === geocity)?._id)
  }, [geocountry, geocity, geoarea, countries, cities, areas]); 

  console.log(country, "country");
  


  return (<>
  {load && <Spinner />}
    <div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb wf-breadcrumb">
            <li class="breadcrumb-item"><a>Menu</a></li>
            <li class="breadcrumb-item"><a>Vendors</a></li>
            <li class="breadcrumb-item active" aria-current="page">Quick Add Vendor</li>
        </ol>
    </nav>
    <h2 className="font-size-28 pink-color dm-font mb-3">Quick Add Vendor</h2>
    <div className="wf-radius-20 white-bg wf-shadow p-3 mb-4">
    <p class="font-size-26 black-color dm-font mb-2">Business Details</p>
      <div className="row gy-3 mb-3">
      <div className="col-md-6">
      <div class="form-floating wf-input w-100">
      <input type="text" onChange={(e) => setstore_name(e.target.value)} value={store_name} class="form-control" id="business-name" placeholder="Business Name" required/>
      <label for="business-name">Business Name</label>
      </div>
      </div>
      <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="date" onChange={(e) => setbusiness_startdate(e.target.value)} onBlur={handleDateChange} max={getCurrentDate()} value={business_startdate} class="form-control" id="Operating-Since" placeholder="12/june/2023"  required/>
      <label for="Operating-Since">Operating Since</label>
      </div>
      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Type of Service</p>
        <div className="d-flex flex-wrap gap-2 mb-3">
        {services?.map((s, i) => (
          <React.Fragment key={i}>
          <input class="btn-check" name="types-of-service" type="radio" id={s?._id} value={s?._id} checked={prefered_services?.includes(s._id)} onChange={() => {setPreferedServices([s?._id]); setpricing(s?.pricing_type)}} />
          <label class="btn wf-select-btn" for={s?._id}>{s?.name}</label>
          </React.Fragment>
        ))}
        </div>

        {servicetypes?.length > 0 ? (<>
          <p class="font-size-20 pink-color dm-font mb-2">Types of Sub Service</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {servicetypes? servicetypes[0]?.subcategories?.map((s) => (
            <>
            <input class="btn-check" type="radio" name="sub-cat" id={s._id} value={s._id} checked={prefered_typeofservices?.includes(s._id)?true:false} onChange={() => setprefered_typeofservices([s._id])} />
            <label class="btn wf-select-btn" for={s._id}>
              {s.name}
            </label>
            </>
          )): null}
          </div>
          </>
          ): null}

          {questions?.length > 0 &&questions?.map((q)=>(
          <> 
          <p class="font-size-20 pink-color dm-font mb-2">{q.question}</p>
          <div className="d-flex flex-wrap gap-2 mb-3">
          {q?.options?.map((s) => (
            <>
            <input class="btn-check" type="checkbox" name="questions" checked={prefered_sub_services?.some((obj) => obj._id == q._id && obj.item?.includes(s._id))} onChange={() => handleSkillChange4(s?._id, q?._id)} />
            <label class="btn wf-select-btn" >{s.name}</label>
            </>
          ))}
          </div>
          </>
            ))}
              
          {initattributes && initattributes[0]?.subcategories?.length > 0 && (
              <>
                <p class="font-size-20 pink-color dm-font mb-2">Which Attributes best suits you</p>
                <div className="d-flex flex-wrap gap-2 mb-3">
                {initattributes[0]?.subcategories?.map((s) => (
                    <>
                      <input className="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={attributes?.includes(s._id)} onChange={() => handleSkillChange2(s?._id)}/>
                      <label className="btn wf-select-btn" htmlFor={s._id}>{s.name}</label>
                    </>
                ))}
                </div>
              </>
            )}

            {initsubattributes && initsubattributes[0]?.subcategories?.length > 0 && (
            <>
              <p class="font-size-20 pink-color dm-font mb-2">What are the extra features (sub attributes) you provide</p>
              <div className="d-flex flex-wrap gap-2 mb-3">
              {initsubattributes[0]?.subcategories?.map((s) => (
                  <>
                    <input className="btn-check" type="checkbox" name="attributes" id={s._id} value={s._id} checked={sub_attributes?.includes(s._id)}  onChange={() => handleSkillChange3( s?._id )}/>
                    <label className="btn wf-select-btn" htmlFor={s._id}>{s.name}</label>
                  </>
              ))}
              </div>
            </>
          )}

      </div>
      <div className="col-md-6">
      <div class="form-floating mb-3 wf-textarea">
        <textarea class="form-control" placeholder="Brief Overview" id="Brief-Overview" onChange={(e) => setoverview(e.target.value)} value={overview} required style={{height:'150px'}}></textarea>
        <label for="Brief-Overview">Brief Overview</label>
      </div>
      </div>
      <div className="col-md-6">
      <p class="font-size-20 pink-color dm-font mb-2">Number of Wedding Hosted</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {numberofWeddings?.map((s) => (
        <>
        <input checked={weddings_hosted==s.text?true:false} class="btn-check" type="radio" name="wedding-hosted" id={s.text} onChange={() => setweddings_hosted(s.text)} value={s.id} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>

      <p class="font-size-20 pink-color dm-font mb-2">Number of Team Members</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {numberofTeam?.map((s) => (
        <>
        <input checked={team_members==s.text?true:false} class="btn-check" type="radio" name="team_members" id={s.text} onChange={() => setteam_members(s.text)} value={s.id} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>
      <p class="font-size-20 pink-color dm-font mb-2">Business Attributes</p>
      <div className="d-flex flex-wrap gap-2 mb-3">
      {BusinessAttributes?.map((s) => (
        <>
        <input checked={business_attributes?.includes(s?.text)} onChange={(e) => setbusiness_attributes([e.target.value])} class="btn-check" type="radio" name="team-members" id={s.text} value={s.text} />
        <label class="btn wf-select-btn" for={s.text}>{s.text}</label>
        </>
      ))}
      </div>
    </div>
    <div className="col-md-6">
    <p class="font-size-20 pink-color dm-font mb-2">Business Services Price Range</p>
    <div className="row mb-3">
    <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="number" onChange={(e) => setmin_price(e.target.value)} placeholder="1,000" value={min_price && min_price} class="form-control" id="Min-Rates" required/>
      <label for="Min-Rates">Min Rates</label>
      </div>
    </div>
    <div className="col-md-6">
      <div class="form-floating wf-input">
      <input type="number" onChange={(e) => setmax_price(e.target.value)} placeholder="10,000" value={max_price && max_price} class="form-control" id="Max-Rates" required/>
      <label for="Max-Rates">Max Rates</label>
      </div>
    </div>
    </div>
    <p class="font-size-20 pink-color dm-font mb-2">Would You Consider Your Business Specialist for Any of the Following Wedding Types?</p>
    <div className="d-flex flex-wrap gap-2 mb-3">
      {ethnics?.map((s, i) => (
        <React.Fragment key={i}>
        <input checked={business_ethnics?.includes(s?._id)} onChange={() => handleEthnicChange(s?._id)}  class="btn-check" type="checkbox" name="team-members" id={s?._id} value={s?._id} />
        <label class="btn wf-select-btn" for={s?._id}>{s?.name}</label>
        </React.Fragment>
      ))}
      </div>
    </div>
    </div>
    <p class="font-size-26 black-color dm-font mb-2">Contact Details</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="url" class="form-control" id="Website" placeholder="Website" value={website} onChange={(e) => setwebsite(e.target.value)} />
      <label for="Website">Website</label>
    </div>
    </div> 
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="email" class="form-control" id="Email" placeholder="Email" onChange={(e) => setemail(e.target.value)} value={email} />
      <label for="Email">Email</label>
    </div>
    </div>  
    <div className="col-md-4 d-flex gap-2 align-items-center">
    <div class="form-floating wf-input w-100">
      <input type="tel" class="form-control" id="Mobile" placeholder="Mobile" value={mobile} onChange={(e) => setmobile(e.target.value)}/>
      <label for="Mobile">Mobile</label>
    </div>
    <div class="form-check form-check-inline wf-checkbox">
      <input  onChange={(e) => setwhatsapp(e.target.checked ? mobile : "")} class="form-check-input" type="checkbox" name="hold-insurance" id="whatsapp" />
      <label class="form-check-label" for="whatsapp">
      Whatsapp
      </label>
    </div>
    </div>  
    
    </div>  
    <p class="font-size-26 black-color dm-font mb-2">Location Details</p>
    <div className="row mb-3">
    <div className="col-md-6">
    <div className="wf-shadow rounded  mb-3">
    <GooglePlacesAutocomplete apiKey={process.env.REACT_GOOGLE_MAPS_API_KEY} selectProps={{ value, onChange: setvalue, placeholder: location ? location : "Enter your location", }} />
    </div>    
    <div class="form-floating mb-3 wf-textarea">
      <textarea class="form-control" placeholder="Address" id="Address" onChange={(e) => setaddress(e.target.value)} value={address}  
      style={{height:'150px'}}></textarea>
      <label for="Address">Address</label>
    </div>
    </div>
    <div className="col-md-6">
    <div class="dropdown wf-select-dropdown mb-3">
      <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {mycities?.length > 0 ? cities?.find(user => user?._id === mycities)?.name : "City"}
      </a>
      <ul class="dropdown-menu">
        {cities?.map((c, i) => (
          <li key={i}><a onClick={() => setmycities(c?._id)} className="dropdown-item">
            {c?.name}
          </a></li>
        ))}
      </ul>
    </div>
      {mycities?.length > 0 && (<>
      <div className="d-flex">
      <div class="form-floating wf-input w-100">
      <input type="text" value={searchValue2} onChange={handleInputChange2} onBlur={handleInputBlur2} class="form-control" id="search-value" placeholder="Area" />
      <label for="search-value">Area</label>
    </div>
      {areas?.length > 0 && areas ?.filter((ci) => ci?.name?.toLowerCase().includes(searchValue2?.toLowerCase()))?.length === 0 && (
      <a onClick={() => handleOptionClick2()} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 d-flex align-items-center">add</a>)}
      </div>
      <div className="dropdown wf-select-dropdown mb-3">
      {showDropdown2 && <ul className={`dropdown-menu show`}>
        <div className="list-autocomplete">
        {mycities?.length > 0 &&areas?.filter((ci) => mycities?.includes(ci?.city_id?._id) && ci?.name.toLowerCase().includes(searchValue2?.toLowerCase()))
            .map((c, i) => (
              <li key={i}>
                <a onClick={() => handleOptionClick2(c?._id)} className="dropdown-item">
                  {c?.name}
                </a>
              </li>
            ))}
        </div>
      </ul>}
    </div>
    </>)}
    </div>
    
    </div>
    <p class="font-size-26 black-color dm-font mb-2">Social Network</p>
    <div className="row gy-3 mb-3">
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Facebook" placeholder="Facebook" value={facebook} onChange={(e) => setfacebook(e.target.value)}/>
      <label for="Facebook">Facebook</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="X" placeholder="X" value={twitter} onChange={(e) => settwitter(e.target.value)}/>
      <label for="X">X</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="LinkedIn" placeholder="LinkedIn" value={linkedin} onChange={(e) => setlinkedin(e.target.value)}/>
      <label for="LinkedIn">LinkedIn</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Instagram" placeholder="Instagram" value={instagram} onChange={(e) => setinstagram(e.target.value)}/>
      <label for="Instagram">Instagram</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Youtube" placeholder="Youtube" value={youtube} onChange={(e) => setyoutube(e.target.value)}/>
      <label for="Youtube">Youtube</label>
    </div>
    </div>
    <div className="col-md-4">
    <div class="form-floating wf-input">
      <input type="text" class="form-control" id="Pinterest" placeholder="Pinterest" value={pinterest} onChange={(e) => setpinterest(e.target.value)}/>
      <label for="Pinterest">Pinterest</label>
    </div>
    </div>
    </div>



        <p class="font-size-26 black-color dm-font mb-2">Photos & Videos</p>

        <div className="upload__img-wrap mb-3">
           {prev_images?.length>0&&prev_images.map((image, index) => (
                <div className="upload__img-box" key={index}>
                  <div className="img-bg">
                    <img src={`${process.env.REACT_APP_IMGURL}${image}`} alt={`Store Image`} className="img-bg-size" />
                    <div className="upload__img-close" onClick={(e) => handleImageRemovePrev(e, index)}></div>
                    <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                      <div class="form-check form-check-inline">
                    <input checked={dp==image?true:false} onChange={()=>setdp(image)} class="form-check-input" type="radio" name="dp" id="dp" />
                    <label class="form-check-label" for="dp">Featured Image</label>
                    </div>
                      </ul>
                    </div>
                </div>
                  </div>
                </div>
              ))}

            {images?.length>0&&images.map((image, index) => (
                <div className="upload__img-box" key={index}>
                  <div className="img-bg">
                    <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
                    <div className="upload__img-close" onClick={(e) => handleImageRemove(e, index)}></div>
                    <div className="upload__img-profile">
                    <div class="dropdown">
                      <a class=""  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <ChevronsDown />
                      </a>
                      <ul class="dropdown-menu">
                      <div class="form-check form-check-inline">
                      <input checked={dp==image.name?true:false} onChange={()=>setdp(image.name)} class="form-check-input" type="radio" name="dp" id="dp" />
                    <label class="form-check-label" for="dp">Featured Image</label>
                    </div>
                      </ul>
                    </div>
                    </div>
                  </div>
                </div>
              ))}
              <input type="file" class="btn-check" id="btn-check" multiple data-max_length="20" onChange={handleImageUpload} accept=".png, .jpg, .jpeg, .webp" />
              <label class="upload__btn" for="btn-check"><Camera width={18} strokeWidth={1} /> Add Photos</label>
            </div>


    </div>

    <div class="d-flex justify-content-center gap-2">
      <button onClick={()=> navigate("/vendors")} class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2">Cancel</button>
      <button onClick={saveBranch} class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" disabled={load}>Submit</button>
    </div>
    </div>
    </>
  )
}

export default QuickAddVendor
